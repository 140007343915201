const GREEK_CITIES = [
  {
    city: 'Athens',
    lat: '37.9842',
    lng: '23.7281',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'primary',
    population: '664046',
    population_proper: '664046',
  },
  {
    city: 'Thessaloniki',
    lat: '40.6403',
    lng: '22.9356',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'admin',
    population: '315196',
    population_proper: '315196',
  },
  {
    city: 'Patra',
    lat: '38.2500',
    lng: '21.7333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'admin',
    population: '167446',
    population_proper: '167446',
  },
  {
    city: 'Piraeus',
    lat: '37.9430',
    lng: '23.6469',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '163688',
    population_proper: '163688',
  },
  {
    city: 'Larisa',
    lat: '39.6369',
    lng: '22.4176',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'admin',
    population: '144651',
    population_proper: '144651',
  },
  {
    city: 'Irakleio',
    lat: '35.3403',
    lng: '25.1344',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'admin',
    population: '140730',
    population_proper: '140730',
  },
  {
    city: 'Peristeri',
    lat: '38.0167',
    lng: '23.6833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '139981',
    population_proper: '139981',
  },
  {
    city: 'Kallithea',
    lat: '37.9500',
    lng: '23.7000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '100641',
    population_proper: '100641',
  },
  {
    city: 'Nikaia',
    lat: '37.9667',
    lng: '23.6333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '89380',
    population_proper: '89380',
  },
  {
    city: 'Glyfada',
    lat: '37.8800',
    lng: '23.7533',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '87305',
    population_proper: '87305',
  },
  {
    city: 'Volos',
    lat: '39.3611',
    lng: '22.9425',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '86046',
    population_proper: '86046',
  },
  {
    city: 'Chania',
    lat: '35.5173',
    lng: '24.0195',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '78728',
    population_proper: '54565',
  },
  {
    city: 'Chalandri',
    lat: '38.0167',
    lng: '23.8000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '74192',
    population_proper: '74192',
  },
  {
    city: 'Kalamata',
    lat: '37.0389',
    lng: '22.1142',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '71823',
    population_proper: '51108',
  },
  {
    city: 'Nea Ionia',
    lat: '38.0333',
    lng: '23.7500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '67134',
    population_proper: '67134',
  },
  {
    city: 'Ioannina',
    lat: '39.6636',
    lng: '20.8522',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'admin',
    population: '65574',
    population_proper: '65574',
  },
  {
    city: 'Palaio Faliro',
    lat: '37.9320',
    lng: '23.7003',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '64021',
    population_proper: '64021',
  },
  {
    city: 'Trikala',
    lat: '39.5550',
    lng: '21.7683',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '61653',
    population_proper: '61653',
  },
  {
    city: 'Vyronas',
    lat: '37.9556',
    lng: '23.7600',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '61308',
    population_proper: '61308',
  },
  {
    city: 'Agia Paraskevi',
    lat: '38.0053',
    lng: '23.8208',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '59704',
    population_proper: '59704',
  },
  {
    city: 'Galatsi',
    lat: '38.0167',
    lng: '23.7500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '59345',
    population_proper: '59345',
  },
  {
    city: 'Kavala',
    lat: '40.9350',
    lng: '24.4150',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '59240',
    population_proper: '52950',
  },
  {
    city: 'Chalkida',
    lat: '38.4625',
    lng: '23.5950',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '59125',
    population_proper: '59125',
  },
  {
    city: 'Serres',
    lat: '41.0833',
    lng: '23.5500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '58287',
    population_proper: '58287',
  },
  {
    city: 'Alexandroúpoli',
    lat: '40.8500',
    lng: '25.8667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '57812',
    population_proper: '57812',
  },
  {
    city: 'Rodos',
    lat: '36.4412',
    lng: '28.2225',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '56969',
    population_proper: '56128',
  },
  {
    city: 'Xanthi',
    lat: '41.1333',
    lng: '24.8833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '56122',
    population_proper: '56122',
  },
  {
    city: 'Katerini',
    lat: '40.2667',
    lng: '22.5000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '55997',
    population_proper: '55997',
  },
  {
    city: 'Lamia',
    lat: '38.8972',
    lng: '22.4311',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'admin',
    population: '52006',
    population_proper: '52006',
  },
  {
    city: 'Komotini',
    lat: '41.1167',
    lng: '25.4000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'admin',
    population: '50990',
    population_proper: '50990',
  },
  {
    city: 'Agrinio',
    lat: '38.6167',
    lng: '21.4000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '46899',
    population_proper: '46899',
  },
  {
    city: 'Chaïdari',
    lat: '38.0167',
    lng: '23.6500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '45642',
    population_proper: '45642',
  },
  {
    city: 'Drama',
    lat: '41.1514',
    lng: '24.1392',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '44823',
    population_proper: '44823',
  },
  {
    city: 'Veroia',
    lat: '40.5203',
    lng: '22.2019',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '43158',
    population_proper: '43158',
  },
  {
    city: 'Kozani',
    lat: '40.3007',
    lng: '21.7890',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'admin',
    population: '41066',
    population_proper: '41066',
  },
  {
    city: 'Karditsa',
    lat: '39.3647',
    lng: '21.9219',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '38554',
    population_proper: '38554',
  },
  {
    city: 'Rethymno',
    lat: '35.3689',
    lng: '24.4739',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '32468',
    population_proper: '32468',
  },
  {
    city: 'Tripoli',
    lat: '37.5083',
    lng: '22.3750',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'admin',
    population: '30866',
    population_proper: '30866',
  },
  {
    city: 'Vrilissia',
    lat: '38.0391',
    lng: '23.8378',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '30741',
    population_proper: '30741',
  },
  {
    city: 'Korinthos',
    lat: '37.9333',
    lng: '22.9333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '30176',
    population_proper: '30176',
  },
  {
    city: 'Giannitsa',
    lat: '40.7962',
    lng: '22.4145',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '29789',
    population_proper: '29789',
  },
  {
    city: 'Mytilini',
    lat: '39.1114',
    lng: '26.5621',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: 'admin',
    population: '29328',
    population_proper: '28322',
  },
  {
    city: 'Chios',
    lat: '38.3725',
    lng: '26.1375',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: 'minor',
    population: '26850',
    population_proper: '26850',
  },
  {
    city: 'Kaisariani',
    lat: '37.9633',
    lng: '23.7653',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '26370',
    population_proper: '26370',
  },
  {
    city: 'Nea Filadelfeia',
    lat: '38.0350',
    lng: '23.7381',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '25734',
    population_proper: '25734',
  },
  {
    city: 'Salamina',
    lat: '37.9333',
    lng: '23.5000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '25370',
    population_proper: '25370',
  },
  {
    city: 'Elefsina',
    lat: '38.0414',
    lng: '23.5453',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '24910',
    population_proper: '24910',
  },
  {
    city: 'Kerkyra',
    lat: '39.6239',
    lng: '19.9214',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: 'admin',
    population: '24838',
    population_proper: '24838',
  },
  {
    city: 'Pyrgos',
    lat: '37.6667',
    lng: '21.4333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '24359',
    population_proper: '24359',
  },
  {
    city: 'Megara',
    lat: '38.0000',
    lng: '23.3333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '23456',
    population_proper: '23456',
  },
  {
    city: 'Kilkis',
    lat: '40.9954',
    lng: '22.8765',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '22914',
    population_proper: '22914',
  },
  {
    city: 'Thebes',
    lat: '38.3242',
    lng: '23.3236',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '22883',
    population_proper: '22883',
  },
  {
    city: 'Lefkada',
    lat: '38.7178',
    lng: '20.6439',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: 'minor',
    population: '22652',
    population_proper: '22652',
  },
  {
    city: 'ano Syros',
    lat: '37.4500',
    lng: '24.9000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '21507',
    population_proper: '21507',
  },
  {
    city: 'Pefki',
    lat: '38.0667',
    lng: '23.8000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '21415',
    population_proper: '21415',
  },
  {
    city: 'Livadeia',
    lat: '38.4361',
    lng: '22.8750',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '21379',
    population_proper: '21379',
  },
  {
    city: 'Kos',
    lat: '36.8153',
    lng: '27.1103',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '19244',
    population_proper: '18691',
  },
  {
    city: 'Preveza',
    lat: '38.9500',
    lng: '20.7500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '19042',
    population_proper: '19042',
  },
  {
    city: 'Orestiada',
    lat: '41.5000',
    lng: '26.5333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '18426',
    population_proper: '18426',
  },
  {
    city: 'Sparti',
    lat: '37.0819',
    lng: '22.4236',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '16187',
    population_proper: '15497',
  },
  {
    city: 'Thermi',
    lat: '40.5472',
    lng: '23.0197',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '16004',
    population_proper: '16004',
  },
  {
    city: 'Alexandreia',
    lat: '40.6283',
    lng: '22.4454',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '14821',
    population_proper: '14821',
  },
  {
    city: 'Paiania',
    lat: '37.9500',
    lng: '23.8500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '14595',
    population_proper: '14595',
  },
  {
    city: 'Nafplio',
    lat: '37.5667',
    lng: '22.8000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '14203',
    population_proper: '14203',
  },
  {
    city: 'Nafpaktos',
    lat: '38.3917',
    lng: '21.8275',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '13415',
    population_proper: '13415',
  },
  {
    city: 'Kastoria',
    lat: '40.5181',
    lng: '21.2688',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '13387',
    population_proper: '13387',
  },
  {
    city: 'Nea Alikarnassos',
    lat: '35.3286',
    lng: '25.1644',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '12925',
    population_proper: '12925',
  },
  {
    city: 'Kalymnos',
    lat: '36.9512',
    lng: '26.9832',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '12324',
    population_proper: '12324',
  },
  {
    city: 'Ermoúpoli',
    lat: '37.4504',
    lng: '24.9333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'admin',
    population: '12260',
    population_proper: '12260',
  },
  {
    city: 'Tyrnavos',
    lat: '39.7353',
    lng: '22.2869',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '11069',
    population_proper: '11069',
  },
  {
    city: 'Diavata',
    lat: '40.6883',
    lng: '22.8583',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '9890',
    population_proper: '9890',
  },
  {
    city: 'Kiato',
    lat: '38.0117',
    lng: '22.7467',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '9812',
    population_proper: '9812',
  },
  {
    city: 'Anatoli',
    lat: '39.6386',
    lng: '20.8661',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: '',
    population: '9798',
    population_proper: '9798',
  },
  {
    city: 'Argostoli',
    lat: '38.1739',
    lng: '20.4883',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: 'minor',
    population: '9748',
    population_proper: '9748',
  },
  {
    city: 'Lykovrysi',
    lat: '38.0667',
    lng: '23.7833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '9738',
    population_proper: '9738',
  },
  {
    city: 'Porto Rafti',
    lat: '37.8844',
    lng: '24.0125',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '9686',
    population_proper: '9686',
  },
  {
    city: 'Psychiko',
    lat: '38.0180',
    lng: '23.7804',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '9529',
    population_proper: '9529',
  },
  {
    city: 'Nea Artaki',
    lat: '38.5167',
    lng: '23.6333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '9489',
    population_proper: '9489',
  },
  {
    city: 'Zefyri',
    lat: '38.0667',
    lng: '23.7167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '9454',
    population_proper: '9454',
  },
  {
    city: 'Siteia',
    lat: '35.2000',
    lng: '26.1000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '9348',
    population_proper: '9348',
  },
  {
    city: 'Nea Moudania',
    lat: '40.2386',
    lng: '23.2814',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '9342',
    population_proper: '9342',
  },
  {
    city: 'Farsala',
    lat: '39.2833',
    lng: '22.3833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '9298',
    population_proper: '9298',
  },
  {
    city: 'Sindos',
    lat: '40.6667',
    lng: '22.8000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '9289',
    population_proper: '9289',
  },
  {
    city: 'Didymoteicho',
    lat: '41.3500',
    lng: '26.5000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '9263',
    population_proper: '9263',
  },
  {
    city: 'Spata',
    lat: '37.9667',
    lng: '23.9167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '9198',
    population_proper: '9198',
  },
  {
    city: 'Epanomi',
    lat: '40.4261',
    lng: '22.9281',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '8979',
    population_proper: '8979',
  },
  {
    city: 'Chrysoúpoli',
    lat: '40.9833',
    lng: '24.7000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '8885',
    population_proper: '8885',
  },
  {
    city: 'Nea Peramos',
    lat: '38.0000',
    lng: '23.4167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '8333',
    population_proper: '8333',
  },
  {
    city: 'Kalampaka',
    lat: '39.7044',
    lng: '21.6269',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '8330',
    population_proper: '8330',
  },
  {
    city: 'Almyros',
    lat: '39.1803',
    lng: '22.7606',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '7955',
    population_proper: '7955',
  },
  {
    city: 'Koufalia',
    lat: '40.7792',
    lng: '22.5767',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '7850',
    population_proper: '7850',
  },
  {
    city: 'Giannouli',
    lat: '39.6672',
    lng: '22.3958',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '7847',
    population_proper: '7847',
  },
  {
    city: 'Lagkadas',
    lat: '40.7500',
    lng: '23.0667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '7764',
    population_proper: '7764',
  },
  {
    city: 'Mournies',
    lat: '35.4850',
    lng: '24.0130',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '7553',
    population_proper: '7553',
  },
  {
    city: 'Elassona',
    lat: '39.8947',
    lng: '22.1886',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '7338',
    population_proper: '7338',
  },
  {
    city: 'Chalastra',
    lat: '40.6267',
    lng: '22.7322',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '7270',
    population_proper: '7270',
  },
  {
    city: 'Nea Kallikrateia',
    lat: '40.3139',
    lng: '23.0633',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '7238',
    population_proper: '7238',
  },
  {
    city: 'Karpenisi',
    lat: '38.9203',
    lng: '21.7833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '7183',
    population_proper: '7183',
  },
  {
    city: 'Marathonas',
    lat: '38.1550',
    lng: '23.9636',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '7170',
    population_proper: '7170',
  },
  {
    city: 'Lavrio',
    lat: '37.7000',
    lng: '24.0500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '7078',
    population_proper: '7078',
  },
  {
    city: 'Polykastro',
    lat: '40.9954',
    lng: '22.5714',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '7064',
    population_proper: '7064',
  },
  {
    city: 'Litochoro',
    lat: '40.1028',
    lng: '22.5069',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '6995',
    population_proper: '6995',
  },
  {
    city: 'Aigina',
    lat: '37.7467',
    lng: '23.4275',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '6867',
    population_proper: '6867',
  },
  {
    city: 'Neo Karlovasi',
    lat: '37.7917',
    lng: '26.7051',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '6708',
    population_proper: '6708',
  },
  {
    city: 'Kato Achaḯa',
    lat: '38.1500',
    lng: '21.5500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '6618',
    population_proper: '6618',
  },
  {
    city: 'Aridaia',
    lat: '40.9750',
    lng: '22.0583',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '6561',
    population_proper: '6561',
  },
  {
    city: 'Soúda',
    lat: '35.4833',
    lng: '24.0667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '6358',
    population_proper: '6358',
  },
  {
    city: 'Kounoupidiana',
    lat: '35.5360',
    lng: '24.0760',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '6334',
    population_proper: '6334',
  },
  {
    city: 'Anavyssos',
    lat: '37.7333',
    lng: '23.9500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '6202',
    population_proper: '6202',
  },
  {
    city: 'Polygyros',
    lat: '40.3783',
    lng: '23.4453',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '6121',
    population_proper: '6121',
  },
  {
    city: 'Messini',
    lat: '37.0500',
    lng: '22.0000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '6065',
    population_proper: '6065',
  },
  {
    city: 'Neoi Epivates',
    lat: '40.5010',
    lng: '22.9090',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '5984',
    population_proper: '5984',
  },
  {
    city: 'Filiatra',
    lat: '37.1572',
    lng: '21.5858',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '5969',
    population_proper: '5969',
  },
  {
    city: 'Psachna',
    lat: '38.5828',
    lng: '23.6328',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '5827',
    population_proper: '5827',
  },
  {
    city: 'Megalopoli',
    lat: '37.4000',
    lng: '22.1333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '5748',
    population_proper: '5748',
  },
  {
    city: 'Myrina',
    lat: '39.8797',
    lng: '25.0742',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: 'minor',
    population: '5711',
    population_proper: '5711',
  },
  {
    city: 'Dionysos',
    lat: '38.1000',
    lng: '23.8667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '5651',
    population_proper: '5651',
  },
  {
    city: 'Nerokoúros',
    lat: '35.4758',
    lng: '24.0383',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '5531',
    population_proper: '5531',
  },
  {
    city: 'Xylokastro',
    lat: '38.0667',
    lng: '22.6333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '5500',
    population_proper: '5500',
  },
  {
    city: 'Filyro',
    lat: '40.6911',
    lng: '23.0042',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '5495',
    population_proper: '5495',
  },
  {
    city: 'Siatista',
    lat: '40.2564',
    lng: '21.5522',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '5490',
    population_proper: '5490',
  },
  {
    city: 'Feres',
    lat: '40.8933',
    lng: '26.1739',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '5457',
    population_proper: '5457',
  },
  {
    city: 'Skydra',
    lat: '40.7667',
    lng: '22.1500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '5406',
    population_proper: '5406',
  },
  {
    city: 'Archangelos',
    lat: '36.2167',
    lng: '28.1167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '5384',
    population_proper: '5384',
  },
  {
    city: 'Kremasti',
    lat: '36.4105',
    lng: '28.1191',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '5363',
    population_proper: '5363',
  },
  {
    city: 'Vrontados',
    lat: '38.4167',
    lng: '26.1333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '5323',
    population_proper: '5323',
  },
  {
    city: 'Tympaki',
    lat: '35.0719',
    lng: '24.7683',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '5285',
    population_proper: '5285',
  },
  {
    city: 'Orchomenos',
    lat: '38.4933',
    lng: '22.9749',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '5238',
    population_proper: '5238',
  },
  {
    city: 'Agria',
    lat: '39.3400',
    lng: '23.0133',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '5191',
    population_proper: '5191',
  },
  {
    city: 'Nea Anchialos',
    lat: '39.2667',
    lng: '22.8167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '5132',
    population_proper: '5132',
  },
  {
    city: 'Kyparissia',
    lat: '37.2333',
    lng: '21.6667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '5131',
    population_proper: '5131',
  },
  {
    city: 'Gargalianoi',
    lat: '37.0667',
    lng: '21.6333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '5007',
    population_proper: '5007',
  },
  {
    city: 'Nigrita',
    lat: '40.9133',
    lng: '23.5014',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '4947',
    population_proper: '4947',
  },
  {
    city: 'Skiathos',
    lat: '39.1652',
    lng: '23.4895',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '4883',
    population_proper: '4883',
  },
  {
    city: 'Chortiatis',
    lat: '40.6083',
    lng: '23.1028',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '4873',
    population_proper: '4873',
  },
  {
    city: 'Aliveri',
    lat: '38.4083',
    lng: '24.0417',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '4827',
    population_proper: '4827',
  },
  {
    city: 'Tinos',
    lat: '37.5377',
    lng: '25.1611',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '4762',
    population_proper: '4762',
  },
  {
    city: 'Magoúla',
    lat: '38.0833',
    lng: '23.5167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '4735',
    population_proper: '4735',
  },
  {
    city: 'Stafidokampos',
    lat: '37.8933',
    lng: '21.2933',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '4716',
    population_proper: '4716',
  },
  {
    city: 'Ampelakia',
    lat: '37.9500',
    lng: '23.5167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '4710',
    population_proper: '4710',
  },
  {
    city: 'Vonitsa',
    lat: '38.9153',
    lng: '20.8911',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '4703',
    population_proper: '4703',
  },
  {
    city: 'Filippiada',
    lat: '39.2028',
    lng: '20.8842',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '4619',
    population_proper: '4619',
  },
  {
    city: 'Aliartos',
    lat: '38.3667',
    lng: '23.1000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '4402',
    population_proper: '4402',
  },
  {
    city: 'Zevgolatio',
    lat: '37.9333',
    lng: '22.8000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '4363',
    population_proper: '4363',
  },
  {
    city: 'Itea',
    lat: '38.4333',
    lng: '22.4333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '4362',
    population_proper: '4362',
  },
  {
    city: 'Istiaia',
    lat: '38.9500',
    lng: '23.1500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '4339',
    population_proper: '4339',
  },
  {
    city: 'Arkalochori',
    lat: '35.1500',
    lng: '25.2667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '4313',
    population_proper: '4313',
  },
  {
    city: 'Gytheio',
    lat: '36.7617',
    lng: '22.5661',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '4279',
    population_proper: '4279',
  },
  {
    city: 'Nea Magnisia',
    lat: '40.6878',
    lng: '22.8458',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '4266',
    population_proper: '4266',
  },
  {
    city: 'Kissamos',
    lat: '35.4833',
    lng: '23.6500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '4236',
    population_proper: '4236',
  },
  {
    city: 'Vouliagmeni',
    lat: '37.8000',
    lng: '23.7833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '4180',
    population_proper: '4180',
  },
  {
    city: 'Aiginio',
    lat: '40.5022',
    lng: '22.5422',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '4153',
    population_proper: '4153',
  },
  {
    city: 'Kitsi',
    lat: '37.8456',
    lng: '23.8267',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '4091',
    population_proper: '4091',
  },
  {
    city: 'Schimatari',
    lat: '38.3500',
    lng: '23.5833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '4035',
    population_proper: '4035',
  },
  {
    city: 'Aitoliko',
    lat: '38.4369',
    lng: '21.3536',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '4012',
    population_proper: '4012',
  },
  {
    city: 'Kranidi',
    lat: '37.3750',
    lng: '23.1583',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '4006',
    population_proper: '4006',
  },
  {
    city: 'Spetses',
    lat: '37.2591',
    lng: '23.1364',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '4001',
    population_proper: '4001',
  },
  {
    city: 'Andravida',
    lat: '37.9058',
    lng: '21.2667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '3981',
    population_proper: '3981',
  },
  {
    city: 'Katsikas',
    lat: '39.6228',
    lng: '20.8875',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: '',
    population: '3885',
    population_proper: '3885',
  },
  {
    city: 'Nea Raidestos',
    lat: '40.5239',
    lng: '23.0508',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3869',
    population_proper: '3869',
  },
  {
    city: 'Nemea',
    lat: '37.8167',
    lng: '22.6667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '3853',
    population_proper: '3853',
  },
  {
    city: 'Potamos',
    lat: '39.6239',
    lng: '19.8792',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '3840',
    population_proper: '3840',
  },
  {
    city: 'Soufli',
    lat: '41.1942',
    lng: '26.3023',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '3837',
    population_proper: '3837',
  },
  {
    city: 'Amfilochia',
    lat: '38.8592',
    lng: '21.1758',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '3827',
    population_proper: '3827',
  },
  {
    city: 'Mykonos',
    lat: '37.4453',
    lng: '25.3287',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '3783',
    population_proper: '3783',
  },
  {
    city: 'Vasilika',
    lat: '40.4800',
    lng: '23.1367',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3762',
    population_proper: '3762',
  },
  {
    city: 'Leonidio',
    lat: '37.1667',
    lng: '22.8500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '3761',
    population_proper: '3761',
  },
  {
    city: 'Lixoúri',
    lat: '38.2000',
    lng: '20.4333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '3752',
    population_proper: '3752',
  },
  {
    city: 'Leptokarya',
    lat: '40.0603',
    lng: '22.5612',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3700',
    population_proper: '3700',
  },
  {
    city: 'Stavros',
    lat: '40.6642',
    lng: '23.6961',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '3672',
    population_proper: '3672',
  },
  {
    city: 'Amyntaio',
    lat: '40.6897',
    lng: '21.6797',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '3671',
    population_proper: '3671',
  },
  {
    city: 'Kimmeria',
    lat: '41.1475',
    lng: '24.9388',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '3644',
    population_proper: '3644',
  },
  {
    city: 'Goumenissa',
    lat: '40.9481',
    lng: '22.4519',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3609',
    population_proper: '3609',
  },
  {
    city: 'Vartholomio',
    lat: '37.8622',
    lng: '21.2058',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '3603',
    population_proper: '3603',
  },
  {
    city: 'Lagyna',
    lat: '40.7233',
    lng: '23.0050',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3591',
    population_proper: '3591',
  },
  {
    city: 'Prosotsani',
    lat: '41.1786',
    lng: '23.9722',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '3553',
    population_proper: '3553',
  },
  {
    city: 'Nea Peramos',
    lat: '40.8389',
    lng: '24.3036',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '3514',
    population_proper: '3514',
  },
  {
    city: 'Korinos',
    lat: '40.3167',
    lng: '22.5833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3487',
    population_proper: '3487',
  },
  {
    city: 'Krinides',
    lat: '41.0131',
    lng: '24.2950',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '3365',
    population_proper: '3365',
  },
  {
    city: 'Velventos',
    lat: '40.2547',
    lng: '22.0742',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '3360',
    population_proper: '3360',
  },
  {
    city: 'Kapandriti',
    lat: '38.2167',
    lng: '23.8833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '3359',
    population_proper: '3359',
  },
  {
    city: 'Sapes',
    lat: '41.0333',
    lng: '25.7000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '3351',
    population_proper: '3351',
  },
  {
    city: 'Vrachati',
    lat: '37.9500',
    lng: '22.8000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '3338',
    population_proper: '3338',
  },
  {
    city: 'Liti',
    lat: '40.7447',
    lng: '22.9781',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3302',
    population_proper: '3302',
  },
  {
    city: 'Ierissos',
    lat: '40.3983',
    lng: '23.8783',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '3266',
    population_proper: '3266',
  },
  {
    city: 'Thasos',
    lat: '40.7781',
    lng: '24.7094',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '3234',
    population_proper: '3234',
  },
  {
    city: 'Ithaki',
    lat: '38.4167',
    lng: '20.6667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: 'minor',
    population: '3231',
    population_proper: '3231',
  },
  {
    city: 'Malia',
    lat: '35.2836',
    lng: '25.4625',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '3224',
    population_proper: '3224',
  },
  {
    city: 'Neos Marmaras',
    lat: '40.0967',
    lng: '23.7831',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3158',
    population_proper: '3158',
  },
  {
    city: 'Zacharo',
    lat: '37.4861',
    lng: '21.6494',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '3145',
    population_proper: '3145',
  },
  {
    city: 'Kallithea',
    lat: '40.2750',
    lng: '22.5750',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3134',
    population_proper: '3134',
  },
  {
    city: 'Meliki',
    lat: '40.5167',
    lng: '22.4000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3116',
    population_proper: '3116',
  },
  {
    city: 'Kalampaki',
    lat: '41.0500',
    lng: '24.1833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '3110',
    population_proper: '3110',
  },
  {
    city: 'Nea Triglia',
    lat: '40.3061',
    lng: '23.2086',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3102',
    population_proper: '3102',
  },
  {
    city: 'Sochos',
    lat: '40.8164',
    lng: '23.3542',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3094',
    population_proper: '3094',
  },
  {
    city: 'Skopelos',
    lat: '39.1167',
    lng: '23.7167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '3090',
    population_proper: '3090',
  },
  {
    city: 'Skala',
    lat: '36.8525',
    lng: '22.6661',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '3067',
    population_proper: '3067',
  },
  {
    city: 'Ntrafi',
    lat: '38.0333',
    lng: '23.9000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '3055',
    population_proper: '3055',
  },
  {
    city: 'Maniakoi',
    lat: '40.5019',
    lng: '21.2447',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '3055',
    population_proper: '3055',
  },
  {
    city: 'Nea Mesimvria',
    lat: '40.7517',
    lng: '22.7692',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '3050',
    population_proper: '3050',
  },
  {
    city: 'Velestino',
    lat: '39.3817',
    lng: '22.7450',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '3044',
    population_proper: '3044',
  },
  {
    city: 'Plomari',
    lat: '38.9833',
    lng: '26.3667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '2996',
    population_proper: '2996',
  },
  {
    city: 'Ormylia',
    lat: '40.2944',
    lng: '23.5433',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2979',
    population_proper: '2979',
  },
  {
    city: 'Neo Rysi',
    lat: '40.4961',
    lng: '22.9881',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2952',
    population_proper: '2952',
  },
  {
    city: 'Konitsa',
    lat: '40.0417',
    lng: '20.7450',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '2942',
    population_proper: '2942',
  },
  {
    city: 'Lefkimmi',
    lat: '39.4167',
    lng: '20.0667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '2935',
    population_proper: '2935',
  },
  {
    city: 'Panaitolio',
    lat: '38.5833',
    lng: '21.4461',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '2935',
    population_proper: '2935',
  },
  {
    city: 'Saronida',
    lat: '37.7333',
    lng: '23.9000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '2932',
    population_proper: '2932',
  },
  {
    city: 'Axioúpoli',
    lat: '40.9858',
    lng: '22.5417',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2897',
    population_proper: '2897',
  },
  {
    city: 'Erythres',
    lat: '38.2167',
    lng: '23.3167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '2862',
    population_proper: '2862',
  },
  {
    city: 'Asprovalta',
    lat: '40.7247',
    lng: '23.7056',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2838',
    population_proper: '2838',
  },
  {
    city: 'Katochi',
    lat: '38.4142',
    lng: '21.2542',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '2829',
    population_proper: '2829',
  },
  {
    city: 'Neochori',
    lat: '38.4083',
    lng: '21.2750',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '2827',
    population_proper: '2827',
  },
  {
    city: 'Kolindros',
    lat: '40.4783',
    lng: '22.4861',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2822',
    population_proper: '2822',
  },
  {
    city: 'Nea Vyssa',
    lat: '41.5833',
    lng: '26.5333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '2805',
    population_proper: '2805',
  },
  {
    city: 'Nea Kios',
    lat: '37.5875',
    lng: '22.7444',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '2778',
    population_proper: '2778',
  },
  {
    city: 'Kassandreia',
    lat: '40.0467',
    lng: '23.4161',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '2775',
    population_proper: '2775',
  },
  {
    city: 'Kamena Voúrla',
    lat: '38.7833',
    lng: '22.7833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '2761',
    population_proper: '2761',
  },
  {
    city: 'Choristi',
    lat: '41.1300',
    lng: '24.2083',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '2725',
    population_proper: '2725',
  },
  {
    city: 'Nea Palatia',
    lat: '38.3167',
    lng: '23.7833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '2723',
    population_proper: '2723',
  },
  {
    city: 'Palaia Fokaia',
    lat: '37.7167',
    lng: '23.9500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '2713',
    population_proper: '2713',
  },
  {
    city: 'Nikiti',
    lat: '40.2228',
    lng: '23.6686',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '2711',
    population_proper: '2711',
  },
  {
    city: 'Karpathos',
    lat: '35.5075',
    lng: '27.2136',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '2707',
    population_proper: '2707',
  },
  {
    city: 'Astakos',
    lat: '38.5333',
    lng: '21.0833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '2696',
    population_proper: '2696',
  },
  {
    city: 'Spercheiada',
    lat: '38.9167',
    lng: '22.1333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '2691',
    population_proper: '2691',
  },
  {
    city: 'Palairos',
    lat: '38.7809',
    lng: '20.8785',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '2664',
    population_proper: '2664',
  },
  {
    city: 'Arachova',
    lat: '38.4789',
    lng: '22.5867',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '2657',
    population_proper: '2657',
  },
  {
    city: 'Lechaina',
    lat: '37.9333',
    lng: '21.2667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '2641',
    population_proper: '2641',
  },
  {
    city: 'Symi',
    lat: '36.5989',
    lng: '27.8342',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '2590',
    population_proper: '2590',
  },
  {
    city: 'Anatoliko',
    lat: '40.6631',
    lng: '22.7114',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2589',
    population_proper: '2589',
  },
  {
    city: 'Kentavros',
    lat: '41.2300',
    lng: '24.9500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '2580',
    population_proper: '2580',
  },
  {
    city: 'Vrachnaiika',
    lat: '38.1669',
    lng: '21.6669',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '2571',
    population_proper: '2571',
  },
  {
    city: 'Rododafni',
    lat: '38.2697',
    lng: '22.0489',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '2564',
    population_proper: '2564',
  },
  {
    city: 'Loutra Aidipsoú',
    lat: '38.8556',
    lng: '23.0464',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '2560',
    population_proper: '2560',
  },
  {
    city: 'Livanates',
    lat: '38.7500',
    lng: '23.0333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '2559',
    population_proper: '2559',
  },
  {
    city: 'Markopoulo',
    lat: '38.2897',
    lng: '23.8247',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '2549',
    population_proper: '2549',
  },
  {
    city: 'Galatista',
    lat: '40.4672',
    lng: '23.2806',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2537',
    population_proper: '2537',
  },
  {
    city: 'Molaoi',
    lat: '36.8042',
    lng: '22.8547',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '2534',
    population_proper: '2534',
  },
  {
    city: 'Kanalaki',
    lat: '39.2325',
    lng: '20.5986',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '2513',
    population_proper: '2513',
  },
  {
    city: 'Filiates',
    lat: '39.6011',
    lng: '20.3119',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '2512',
    population_proper: '2512',
  },
  {
    city: 'Ermioni',
    lat: '37.3833',
    lng: '23.2500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '2505',
    population_proper: '2505',
  },
  {
    city: 'Metsovo',
    lat: '39.7703',
    lng: '21.1839',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '2503',
    population_proper: '2503',
  },
  {
    city: 'Echinos',
    lat: '41.2758',
    lng: '24.9725',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '2486',
    population_proper: '2486',
  },
  {
    city: 'Ligourio',
    lat: '37.6144',
    lng: '23.0361',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '2482',
    population_proper: '2482',
  },
  {
    city: 'Limenaria',
    lat: '40.6278',
    lng: '24.5767',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '2471',
    population_proper: '2471',
  },
  {
    city: 'Gonnoi',
    lat: '39.8611',
    lng: '22.4761',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '2462',
    population_proper: '2462',
  },
  {
    city: 'Nea Malgara',
    lat: '40.6097',
    lng: '22.6819',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2404',
    population_proper: '2404',
  },
  {
    city: 'Pella',
    lat: '40.7617',
    lng: '22.5264',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2398',
    population_proper: '2398',
  },
  {
    city: 'Lefkonas',
    lat: '41.0983',
    lng: '23.4961',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2388',
    population_proper: '2388',
  },
  {
    city: 'Kymi',
    lat: '38.6343',
    lng: '24.1038',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '2383',
    population_proper: '2383',
  },
  {
    city: 'Paramythia',
    lat: '39.4667',
    lng: '20.5000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '2363',
    population_proper: '2363',
  },
  {
    city: 'Pylos',
    lat: '36.9000',
    lng: '21.6833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '2345',
    population_proper: '2345',
  },
  {
    city: 'Agiasos',
    lat: '39.0833',
    lng: '26.3711',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '2320',
    population_proper: '2320',
  },
  {
    city: 'Tychero',
    lat: '41.0289',
    lng: '26.2944',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '2311',
    population_proper: '2311',
  },
  {
    city: 'Arnaia',
    lat: '40.4867',
    lng: '23.5947',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2300',
    population_proper: '2300',
  },
  {
    city: 'Varda',
    lat: '38.0306',
    lng: '21.3650',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '2291',
    population_proper: '2291',
  },
  {
    city: 'Dimini',
    lat: '39.3547',
    lng: '22.8892',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '2261',
    population_proper: '2261',
  },
  {
    city: 'Makrakomi',
    lat: '38.9333',
    lng: '22.1167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '2245',
    population_proper: '2245',
  },
  {
    city: 'Livadi',
    lat: '40.1333',
    lng: '22.1500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '2244',
    population_proper: '2244',
  },
  {
    city: 'Nikisiani',
    lat: '40.9489',
    lng: '24.1417',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '2236',
    population_proper: '2236',
  },
  {
    city: 'agios Kirykos',
    lat: '37.6114',
    lng: '26.2931',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: 'minor',
    population: '2218',
    population_proper: '2218',
  },
  {
    city: 'Galatas',
    lat: '37.4967',
    lng: '23.4475',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '2195',
    population_proper: '2195',
  },
  {
    city: 'Kyriaki',
    lat: '38.3500',
    lng: '22.7833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '2185',
    population_proper: '2185',
  },
  {
    city: 'Zagkliveri',
    lat: '40.5719',
    lng: '23.2889',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2171',
    population_proper: '2171',
  },
  {
    city: 'Terpni',
    lat: '40.9167',
    lng: '23.4833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2169',
    population_proper: '2169',
  },
  {
    city: 'Nea Karvali',
    lat: '40.9606',
    lng: '24.5106',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '2160',
    population_proper: '2160',
  },
  {
    city: 'Kato Nevrokopi',
    lat: '41.3439',
    lng: '23.8667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '2157',
    population_proper: '2157',
  },
  {
    city: 'Kato Goúves',
    lat: '35.3300',
    lng: '25.3119',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '2153',
    population_proper: '2153',
  },
  {
    city: 'Anthoúsa',
    lat: '38.0333',
    lng: '23.8667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '2132',
    population_proper: '2132',
  },
  {
    city: 'Alistrati',
    lat: '41.0633',
    lng: '23.9581',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2127',
    population_proper: '2127',
  },
  {
    city: 'Mintilogli',
    lat: '38.1833',
    lng: '21.7000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '2120',
    population_proper: '2120',
  },
  {
    city: 'Zaros',
    lat: '35.1333',
    lng: '24.9000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '2106',
    population_proper: '2106',
  },
  {
    city: 'Polichnitos',
    lat: '39.0790',
    lng: '26.1810',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '2102',
    population_proper: '2102',
  },
  {
    city: 'Mesopotamia',
    lat: '40.5000',
    lng: '21.1500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '2099',
    population_proper: '2099',
  },
  {
    city: 'Tagarades',
    lat: '40.4889',
    lng: '23.0222',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2088',
    population_proper: '2088',
  },
  {
    city: 'Parga',
    lat: '39.2833',
    lng: '20.4000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: '',
    population: '2088',
    population_proper: '2088',
  },
  {
    city: 'Rodopoli',
    lat: '38.1000',
    lng: '23.8667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '2078',
    population_proper: '2078',
  },
  {
    city: 'Zagora',
    lat: '39.4428',
    lng: '23.1017',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '2074',
    population_proper: '2074',
  },
  {
    city: 'Antimacheia',
    lat: '36.8125',
    lng: '27.0983',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '2068',
    population_proper: '2068',
  },
  {
    city: 'Karditsomagoúla',
    lat: '39.3917',
    lng: '21.9233',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '2063',
    population_proper: '2063',
  },
  {
    city: 'Selero',
    lat: '41.1333',
    lng: '25.0000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '2059',
    population_proper: '2059',
  },
  {
    city: 'Tsaritsani',
    lat: '39.8820',
    lng: '22.2280',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '2040',
    population_proper: '2040',
  },
  {
    city: 'Karitsa',
    lat: '40.1875',
    lng: '22.4819',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '2025',
    population_proper: '2025',
  },
  {
    city: 'Galaxidi',
    lat: '38.3767',
    lng: '22.3836',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '2011',
    population_proper: '2011',
  },
  {
    city: 'Pikermi',
    lat: '38.0000',
    lng: '23.9333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '2009',
    population_proper: '2009',
  },
  {
    city: 'Nea Manolada',
    lat: '38.0500',
    lng: '21.3833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '2006',
    population_proper: '2006',
  },
  {
    city: 'Aiani',
    lat: '40.1647',
    lng: '21.8208',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '2006',
    population_proper: '2006',
  },
  {
    city: 'Kalloni',
    lat: '39.2328',
    lng: '26.2069',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '1978',
    population_proper: '1978',
  },
  {
    city: 'Dilesi',
    lat: '38.3425',
    lng: '23.6728',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1976',
    population_proper: '1976',
  },
  {
    city: 'Svoronos',
    lat: '40.2667',
    lng: '22.4667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1948',
    population_proper: '1948',
  },
  {
    city: 'Emporeio',
    lat: '36.3583',
    lng: '25.4444',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '1938',
    population_proper: '1938',
  },
  {
    city: 'Loúros',
    lat: '39.1667',
    lng: '20.7500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: '',
    population: '1938',
    population_proper: '1938',
  },
  {
    city: 'Neos Skopos',
    lat: '41.0239',
    lng: '23.6108',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1934',
    population_proper: '1934',
  },
  {
    city: 'Afidnes',
    lat: '38.2000',
    lng: '23.8333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1908',
    population_proper: '1908',
  },
  {
    city: 'Vrontoú',
    lat: '40.1936',
    lng: '22.4319',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1902',
    population_proper: '1902',
  },
  {
    city: 'Gaïtani',
    lat: '37.7833',
    lng: '20.8667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '1899',
    population_proper: '1899',
  },
  {
    city: 'Samos',
    lat: '37.7475',
    lng: '26.9825',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: 'minor',
    population: '1888',
    population_proper: '1888',
  },
  {
    city: 'Krestena',
    lat: '37.5917',
    lng: '21.6200',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '1864',
    population_proper: '1864',
  },
  {
    city: 'Megala Kalyvia',
    lat: '39.5000',
    lng: '21.7833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '1849',
    population_proper: '1849',
  },
  {
    city: 'Mavrommati',
    lat: '38.3278',
    lng: '23.1408',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1847',
    population_proper: '1847',
  },
  {
    city: 'Kato Tithorea',
    lat: '38.6000',
    lng: '22.7167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '1841',
    population_proper: '1841',
  },
  {
    city: 'Athikia',
    lat: '37.8167',
    lng: '22.9333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1834',
    population_proper: '1834',
  },
  {
    city: 'Kolchiko',
    lat: '40.7581',
    lng: '23.1347',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1831',
    population_proper: '1831',
  },
  {
    city: 'Kalamos',
    lat: '38.2847',
    lng: '23.8637',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1824',
    population_proper: '1824',
  },
  {
    city: 'Desfina',
    lat: '38.4184',
    lng: '22.5287',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1824',
    population_proper: '1824',
  },
  {
    city: 'Porto Cheli',
    lat: '37.3250',
    lng: '23.1420',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1817',
    population_proper: '1817',
  },
  {
    city: 'Verdikoússa',
    lat: '39.7808',
    lng: '21.9764',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '1812',
    population_proper: '1812',
  },
  {
    city: 'Platykampos',
    lat: '39.6217',
    lng: '22.5350',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '1804',
    population_proper: '1804',
  },
  {
    city: 'Galatini',
    lat: '40.3197',
    lng: '21.5519',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '1795',
    population_proper: '1795',
  },
  {
    city: 'Mantoúdi',
    lat: '38.7981',
    lng: '23.4797',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1787',
    population_proper: '1787',
  },
  {
    city: 'Promachoi',
    lat: '41.0236',
    lng: '22.0081',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1740',
    population_proper: '1740',
  },
  {
    city: 'Evropos',
    lat: '40.8975',
    lng: '22.5525',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1734',
    population_proper: '1734',
  },
  {
    city: 'Makrisia',
    lat: '37.6167',
    lng: '21.6000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1718',
    population_proper: '1718',
  },
  {
    city: 'Thermo',
    lat: '38.5736',
    lng: '21.6664',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '1716',
    population_proper: '1716',
  },
  {
    city: 'Petroússa',
    lat: '41.1944',
    lng: '24.0158',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1704',
    population_proper: '1704',
  },
  {
    city: 'Chiliomodi',
    lat: '37.8139',
    lng: '22.8711',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1699',
    population_proper: '1699',
  },
  {
    city: 'Nea Santa',
    lat: '40.8428',
    lng: '22.9194',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1693',
    population_proper: '1693',
  },
  {
    city: 'Katoúna',
    lat: '38.7853',
    lng: '21.1167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1677',
    population_proper: '1677',
  },
  {
    city: 'Kalavryta',
    lat: '38.0308',
    lng: '22.1086',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '1674',
    population_proper: '1674',
  },
  {
    city: 'Argyropoúli',
    lat: '39.8261',
    lng: '22.3081',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '1667',
    population_proper: '1667',
  },
  {
    city: 'Kontariotissa',
    lat: '40.2272',
    lng: '22.4594',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1662',
    population_proper: '1662',
  },
  {
    city: 'Kontokali',
    lat: '39.6460',
    lng: '19.8500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '1660',
    population_proper: '1660',
  },
  {
    city: 'Skyros',
    lat: '38.9064',
    lng: '24.5658',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '1657',
    population_proper: '1657',
  },
  {
    city: 'Evinochori',
    lat: '38.3594',
    lng: '21.5361',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1651',
    population_proper: '1651',
  },
  {
    city: 'Kardamaina',
    lat: '36.7818',
    lng: '27.1419',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '1650',
    population_proper: '1650',
  },
  {
    city: 'Limni',
    lat: '38.7700',
    lng: '23.3200',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '1642',
    population_proper: '1642',
  },
  {
    city: 'Palaia Epidavros',
    lat: '37.6379',
    lng: '23.1570',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1618',
    population_proper: '1618',
  },
  {
    city: 'Perigiali',
    lat: '37.9380',
    lng: '22.8360',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1616',
    population_proper: '1616',
  },
  {
    city: 'Makrychori',
    lat: '39.8000',
    lng: '22.4833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '1615',
    population_proper: '1615',
  },
  {
    city: 'Ampelokipoi',
    lat: '37.7581',
    lng: '20.8725',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '1606',
    population_proper: '1606',
  },
  {
    city: 'Nea Flogita',
    lat: '40.2600',
    lng: '23.2200',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1604',
    population_proper: '1604',
  },
  {
    city: 'Distomo',
    lat: '38.4293',
    lng: '22.6673',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '1589',
    population_proper: '1589',
  },
  {
    city: 'Vari',
    lat: '37.3906',
    lng: '24.9447',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '1584',
    population_proper: '1584',
  },
  {
    city: 'Thymiana',
    lat: '38.3144',
    lng: '26.1325',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '1566',
    population_proper: '1566',
  },
  {
    city: 'Nea efesos',
    lat: '40.2297',
    lng: '22.5003',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1564',
    population_proper: '1564',
  },
  {
    city: 'Nea Iraklitsa',
    lat: '40.8644',
    lng: '24.3167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1560',
    population_proper: '1560',
  },
  {
    city: 'Nea Poteidaia',
    lat: '40.1931',
    lng: '23.3279',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1559',
    population_proper: '1559',
  },
  {
    city: 'Kompoti',
    lat: '39.1022',
    lng: '21.0837',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: '',
    population: '1544',
    population_proper: '1544',
  },
  {
    city: 'Domokos',
    lat: '39.1000',
    lng: '22.3333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '1531',
    population_proper: '1531',
  },
  {
    city: 'Gra Lygia',
    lat: '35.0150',
    lng: '25.6922',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '1528',
    population_proper: '1528',
  },
  {
    city: 'Lofos',
    lat: '40.2417',
    lng: '22.3833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1496',
    population_proper: '1496',
  },
  {
    city: 'Zygos',
    lat: '41.0167',
    lng: '24.3833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1485',
    population_proper: '1485',
  },
  {
    city: 'Filoti',
    lat: '37.0519',
    lng: '25.4983',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '1477',
    population_proper: '1477',
  },
  {
    city: 'Perdika',
    lat: '39.3667',
    lng: '20.3000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: '',
    population: '1476',
    population_proper: '1476',
  },
  {
    city: 'Genisea',
    lat: '41.0614',
    lng: '24.9624',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '1473',
    population_proper: '1473',
  },
  {
    city: 'Kokkinochoma',
    lat: '40.9261',
    lng: '24.2983',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1472',
    population_proper: '1472',
  },
  {
    city: 'Epitalio',
    lat: '37.6267',
    lng: '21.4933',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1469',
    population_proper: '1469',
  },
  {
    city: 'Pyrgetos',
    lat: '39.9181',
    lng: '22.5956',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '1463',
    population_proper: '1463',
  },
  {
    city: 'Aigeira',
    lat: '38.1456',
    lng: '22.3506',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1462',
    population_proper: '1462',
  },
  {
    city: 'Tolo',
    lat: '37.5205',
    lng: '22.8600',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1460',
    population_proper: '1460',
  },
  {
    city: 'Antikyra',
    lat: '38.3833',
    lng: '22.6333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1448',
    population_proper: '1448',
  },
  {
    city: 'Kineta',
    lat: '37.9717',
    lng: '23.2150',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1446',
    population_proper: '1446',
  },
  {
    city: 'Kastelli',
    lat: '35.2089',
    lng: '25.3378',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '1438',
    population_proper: '1438',
  },
  {
    city: 'Keramoti',
    lat: '40.8556',
    lng: '24.6983',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1438',
    population_proper: '1438',
  },
  {
    city: 'Grammatiko',
    lat: '38.2025',
    lng: '23.9650',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1432',
    population_proper: '1432',
  },
  {
    city: 'Skala Oropoú',
    lat: '38.3197',
    lng: '23.7875',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1430',
    population_proper: '1430',
  },
  {
    city: 'Profitis Ilias',
    lat: '35.2064',
    lng: '25.1000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '1429',
    population_proper: '1429',
  },
  {
    city: 'Kouvaras',
    lat: '37.8333',
    lng: '23.9667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1426',
    population_proper: '1426',
  },
  {
    city: 'Magoúla',
    lat: '37.0667',
    lng: '22.4000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1415',
    population_proper: '1415',
  },
  {
    city: 'Koroni',
    lat: '36.7961',
    lng: '21.9581',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1409',
    population_proper: '1409',
  },
  {
    city: 'Mithymna',
    lat: '39.3686',
    lng: '26.1806',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '1399',
    population_proper: '1399',
  },
  {
    city: 'Atsipopoulo',
    lat: '35.3527',
    lng: '24.4340',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '1392',
    population_proper: '1392',
  },
  {
    city: 'Kirra',
    lat: '38.4334',
    lng: '22.4400',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1385',
    population_proper: '1385',
  },
  {
    city: 'Polydendri',
    lat: '38.2167',
    lng: '23.8667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1385',
    population_proper: '1385',
  },
  {
    city: 'Potamia',
    lat: '40.7164',
    lng: '24.7283',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1383',
    population_proper: '1383',
  },
  {
    city: 'Lardos',
    lat: '36.0941',
    lng: '28.0166',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '1380',
    population_proper: '1380',
  },
  {
    city: 'Damasi',
    lat: '39.7125',
    lng: '22.1875',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '1380',
    population_proper: '1380',
  },
  {
    city: 'Perivoli',
    lat: '39.4208',
    lng: '20.0139',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '1378',
    population_proper: '1378',
  },
  {
    city: 'Katastari',
    lat: '37.8278',
    lng: '20.7567',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '1378',
    population_proper: '1378',
  },
  {
    city: 'Kali',
    lat: '40.8177',
    lng: '22.1782',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1378',
    population_proper: '1378',
  },
  {
    city: 'Thesprotiko',
    lat: '39.2497',
    lng: '20.7811',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: '',
    population: '1363',
    population_proper: '1363',
  },
  {
    city: 'Kamari',
    lat: '36.3741',
    lng: '25.4820',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '1344',
    population_proper: '1344',
  },
  {
    city: 'Kyria',
    lat: '41.0985',
    lng: '24.2892',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1330',
    population_proper: '1330',
  },
  {
    city: 'Varnavas',
    lat: '38.2236',
    lng: '23.9236',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1326',
    population_proper: '1326',
  },
  {
    city: 'Argalasti',
    lat: '39.2257',
    lng: '23.2197',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '1321',
    population_proper: '1321',
  },
  {
    city: 'Myki',
    lat: '41.2447',
    lng: '24.9192',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1313',
    population_proper: '1313',
  },
  {
    city: 'Meligalas',
    lat: '37.2167',
    lng: '21.9667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '1296',
    population_proper: '1296',
  },
  {
    city: 'Kritsa',
    lat: '35.1567',
    lng: '25.6444',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '1296',
    population_proper: '1296',
  },
  {
    city: 'Grizano',
    lat: '39.6319',
    lng: '22.0525',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '1290',
    population_proper: '1290',
  },
  {
    city: 'Mavrochori',
    lat: '40.5114',
    lng: '21.3208',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '1287',
    population_proper: '1287',
  },
  {
    city: 'Kallifytos',
    lat: '41.1728',
    lng: '24.2153',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1282',
    population_proper: '1282',
  },
  {
    city: 'Palaiokomi',
    lat: '40.8714',
    lng: '23.9017',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1274',
    population_proper: '1274',
  },
  {
    city: 'Rizia',
    lat: '41.6219',
    lng: '26.4222',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1272',
    population_proper: '1272',
  },
  {
    city: 'Vilia',
    lat: '38.1667',
    lng: '23.3333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1269',
    population_proper: '1269',
  },
  {
    city: 'Skarmagkas',
    lat: '38.0100',
    lng: '23.6000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1255',
    population_proper: '1255',
  },
  {
    city: 'Kavasila',
    lat: '37.8750',
    lng: '21.2667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1252',
    population_proper: '1252',
  },
  {
    city: 'Aidipsos',
    lat: '38.8500',
    lng: '23.0333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1249',
    population_proper: '1249',
  },
  {
    city: 'Zarkos',
    lat: '39.6078',
    lng: '22.1247',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '1247',
    population_proper: '1247',
  },
  {
    city: 'Gergeri',
    lat: '35.1329',
    lng: '24.9499',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '1241',
    population_proper: '1241',
  },
  {
    city: 'Karterados',
    lat: '36.4133',
    lng: '25.4458',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '1238',
    population_proper: '1238',
  },
  {
    city: 'Stalida',
    lat: '35.2914',
    lng: '25.4284',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '1237',
    population_proper: '1237',
  },
  {
    city: 'Livadero',
    lat: '40.0333',
    lng: '21.9425',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '1232',
    population_proper: '1232',
  },
  {
    city: 'Davleia',
    lat: '38.5167',
    lng: '22.7333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1232',
    population_proper: '1232',
  },
  {
    city: 'Angelokastro',
    lat: '38.5667',
    lng: '21.3000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1220',
    population_proper: '1220',
  },
  {
    city: 'Fyteies',
    lat: '38.6953',
    lng: '21.1858',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1210',
    population_proper: '1210',
  },
  {
    city: 'Savalia',
    lat: '37.8167',
    lng: '21.2833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1206',
    population_proper: '1206',
  },
  {
    city: 'Dafnes',
    lat: '35.2161',
    lng: '25.0503',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '1204',
    population_proper: '1204',
  },
  {
    city: 'Amaxades',
    lat: '41.1167',
    lng: '25.0667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1195',
    population_proper: '1195',
  },
  {
    city: 'Angelochorion',
    lat: '40.4970',
    lng: '22.8340',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1178',
    population_proper: '1178',
  },
  {
    city: 'Proti',
    lat: '40.9442',
    lng: '24.0017',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1176',
    population_proper: '1176',
  },
  {
    city: 'Kitros',
    lat: '40.3722',
    lng: '22.5789',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1172',
    population_proper: '1172',
  },
  {
    city: 'Kampanis',
    lat: '40.8928',
    lng: '22.9153',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1170',
    population_proper: '1170',
  },
  {
    city: 'Sylivainiotika',
    lat: '38.1614',
    lng: '22.3317',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1165',
    population_proper: '1165',
  },
  {
    city: 'Lefkopigi',
    lat: '40.2310',
    lng: '21.7490',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '1161',
    population_proper: '1161',
  },
  {
    city: 'Agia Triada',
    lat: '37.6377',
    lng: '22.8041',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1151',
    population_proper: '1151',
  },
  {
    city: 'Arriana',
    lat: '41.0813',
    lng: '25.6949',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1147',
    population_proper: '1147',
  },
  {
    city: 'Loutro Elenis',
    lat: '37.8660',
    lng: '22.9970',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1145',
    population_proper: '1145',
  },
  {
    city: 'Perachora',
    lat: '38.0314',
    lng: '22.9486',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1141',
    population_proper: '1141',
  },
  {
    city: 'Myrsini',
    lat: '37.9167',
    lng: '21.2333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1135',
    population_proper: '1135',
  },
  {
    city: 'Isthmia',
    lat: '37.9155',
    lng: '23.0073',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1134',
    population_proper: '1134',
  },
  {
    city: 'Kala Dendra',
    lat: '41.0994',
    lng: '23.4239',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1131',
    population_proper: '1131',
  },
  {
    city: 'Dion',
    lat: '40.1719',
    lng: '22.4825',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1130',
    population_proper: '1130',
  },
  {
    city: 'Chrysokellaria',
    lat: '36.7919',
    lng: '21.8894',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1128',
    population_proper: '1128',
  },
  {
    city: 'Nea Plagia',
    lat: '40.2625',
    lng: '23.2028',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1127',
    population_proper: '1127',
  },
  {
    city: 'Polydrosos',
    lat: '38.6403',
    lng: '22.5317',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1125',
    population_proper: '1125',
  },
  {
    city: 'Foúrnoi',
    lat: '37.5908',
    lng: '26.5022',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: 'minor',
    population: '1120',
    population_proper: '1120',
  },
  {
    city: 'Zoniana',
    lat: '35.2947',
    lng: '24.8308',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '1117',
    population_proper: '1117',
  },
  {
    city: 'Pontokomi',
    lat: '40.4106',
    lng: '21.7619',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '1116',
    population_proper: '1116',
  },
  {
    city: 'Koila',
    lat: '40.3306',
    lng: '21.7886',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: '',
    population: '1115',
    population_proper: '1115',
  },
  {
    city: 'Aneza',
    lat: '39.0867',
    lng: '20.9236',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: '',
    population: '1111',
    population_proper: '1111',
  },
  {
    city: 'Petra',
    lat: '39.3269',
    lng: '26.1764',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '1108',
    population_proper: '1108',
  },
  {
    city: 'Varvasaina',
    lat: '37.6719',
    lng: '21.4983',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1107',
    population_proper: '1107',
  },
  {
    city: 'Methoni',
    lat: '36.8217',
    lng: '21.7069',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1103',
    population_proper: '1103',
  },
  {
    city: 'Lavara',
    lat: '41.2667',
    lng: '26.3833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1093',
    population_proper: '1093',
  },
  {
    city: 'Eresos',
    lat: '39.1667',
    lng: '25.9331',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '1086',
    population_proper: '1086',
  },
  {
    city: 'Vranas',
    lat: '38.1244',
    lng: '23.9475',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: '',
    population: '1082',
    population_proper: '1082',
  },
  {
    city: 'Charakopio',
    lat: '36.8086',
    lng: '21.9133',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1080',
    population_proper: '1080',
  },
  {
    city: 'Kato Asites',
    lat: '35.2000',
    lng: '25.0000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: '',
    population: '1076',
    population_proper: '1076',
  },
  {
    city: 'Marathokampos',
    lat: '37.7269',
    lng: '26.6883',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '1069',
    population_proper: '1069',
  },
  {
    city: 'Petalidi',
    lat: '36.9500',
    lng: '21.9333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1065',
    population_proper: '1065',
  },
  {
    city: 'Andromachi',
    lat: '40.2719',
    lng: '22.4811',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1061',
    population_proper: '1061',
  },
  {
    city: 'Kokkari',
    lat: '37.7758',
    lng: '26.8867',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: '',
    population: '1060',
    population_proper: '1060',
  },
  {
    city: 'Kastanies',
    lat: '41.6500',
    lng: '26.4833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: '',
    population: '1059',
    population_proper: '1059',
  },
  {
    city: 'Stratoni',
    lat: '40.5200',
    lng: '23.8200',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: '',
    population: '1057',
    population_proper: '1057',
  },
  {
    city: 'Astypalaia',
    lat: '36.5475',
    lng: '26.3528',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '1055',
    population_proper: '1055',
  },
  {
    city: 'Kandila',
    lat: '38.7000',
    lng: '20.9500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1048',
    population_proper: '1048',
  },
  {
    city: 'Vanato',
    lat: '37.7930',
    lng: '20.8530',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '1045',
    population_proper: '1045',
  },
  {
    city: 'Paralio astros',
    lat: '37.4167',
    lng: '22.7661',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1043',
    population_proper: '1043',
  },
  {
    city: 'Sagaiika',
    lat: '38.1000',
    lng: '21.4667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1038',
    population_proper: '1038',
  },
  {
    city: 'Alfeioúsa',
    lat: '37.6333',
    lng: '21.5333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1037',
    population_proper: '1037',
  },
  {
    city: 'Nea Styra',
    lat: '38.1810',
    lng: '24.2080',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1031',
    population_proper: '1031',
  },
  {
    city: 'Levidi',
    lat: '37.6833',
    lng: '22.2833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: '',
    population: '1025',
    population_proper: '1025',
  },
  {
    city: 'Trikeri',
    lat: '39.1003',
    lng: '23.0769',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: '',
    population: '1022',
    population_proper: '1022',
  },
  {
    city: 'Antirrio',
    lat: '38.3306',
    lng: '21.7647',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1018',
    population_proper: '1018',
  },
  {
    city: 'Acharavi',
    lat: '39.7968',
    lng: '19.8111',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: '',
    population: '1013',
    population_proper: '1013',
  },
  {
    city: 'Eleonas',
    lat: '38.3633',
    lng: '23.4428',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: '',
    population: '1010',
    population_proper: '1010',
  },
  {
    city: 'Batsi',
    lat: '37.8586',
    lng: '24.7864',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: '',
    population: '1010',
    population_proper: '1010',
  },
  {
    city: 'Kallikomo',
    lat: '37.5833',
    lng: '21.5833',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: '',
    population: '1008',
    population_proper: '1008',
  },
  {
    city: 'Chalandritsa',
    lat: '38.1167',
    lng: '21.8000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '913',
    population_proper: '913',
  },
  {
    city: 'Fillyra',
    lat: '41.1167',
    lng: '25.6333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '909',
    population_proper: '909',
  },
  {
    city: 'Lidoriki',
    lat: '38.5333',
    lng: '22.2000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '875',
    population_proper: '875',
  },
  {
    city: 'Nestorio',
    lat: '40.4122',
    lng: '21.0617',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '865',
    population_proper: '865',
  },
  {
    city: 'Archaia Olympia',
    lat: '37.6443',
    lng: '21.6253',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '835',
    population_proper: '835',
  },
  {
    city: 'Leipsoi',
    lat: '37.3000',
    lng: '26.7500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '790',
    population_proper: '790',
  },
  {
    city: 'Milos',
    lat: '36.7417',
    lng: '24.4292',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '749',
    population_proper: '749',
  },
  {
    city: 'Mandraki',
    lat: '36.6108',
    lng: '27.1367',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '660',
    population_proper: '660',
  },
  {
    city: 'Tzermiado',
    lat: '35.1983',
    lng: '25.4900',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '637',
    population_proper: '637',
  },
  {
    city: 'Kalpaki',
    lat: '39.8861',
    lng: '20.6261',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '625',
    population_proper: '625',
  },
  {
    city: 'Paranesti',
    lat: '41.2667',
    lng: '24.5014',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '625',
    population_proper: '625',
  },
  {
    city: 'Spili',
    lat: '35.2189',
    lng: '24.5361',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '564',
    population_proper: '564',
  },
  {
    city: 'Megisti',
    lat: '36.1450',
    lng: '29.5850',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '492',
    population_proper: '492',
  },
  {
    city: 'Peza',
    lat: '35.2178',
    lng: '25.1928',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '441',
    population_proper: '441',
  },
  {
    city: 'Serifos',
    lat: '37.1500',
    lng: '24.5000',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '364',
    population_proper: '364',
  },
  {
    city: 'Dimitsana',
    lat: '37.5952',
    lng: '22.0403',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '342',
    population_proper: '342',
  },
  {
    city: 'Vourgareli',
    lat: '39.3722',
    lng: '21.1817',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '301',
    population_proper: '301',
  },
  {
    city: 'Kardamyli',
    lat: '36.8867',
    lng: '22.2322',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '300',
    population_proper: '300',
  },
  {
    city: 'Kythira',
    lat: '36.1497',
    lng: '22.9879',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '281',
    population_proper: '281',
  },
  {
    city: 'Alonnisos',
    lat: '39.1500',
    lng: '23.8500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '208',
    population_proper: '208',
  },
  {
    city: 'Sikinos',
    lat: '36.6833',
    lng: '25.1167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '184',
    population_proper: '184',
  },
  {
    city: 'Kerasochori',
    lat: '39.0056',
    lng: '21.6378',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '174',
    population_proper: '174',
  },
  {
    city: 'Anthiro',
    lat: '39.3414',
    lng: '21.4539',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '167',
    population_proper: '167',
  },
  {
    city: 'Nea Smyrni',
    lat: '37.9454',
    lng: '23.7146',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Ampelokipoi',
    lat: '40.6540',
    lng: '22.9175',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Dafni',
    lat: '37.9501',
    lng: '23.7312',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'agios Dimitrios',
    lat: '37.9342',
    lng: '23.7291',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Korydallos',
    lat: '37.9811',
    lng: '23.6514',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Stavroúpoli',
    lat: '40.6692',
    lng: '22.9310',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Kalamaria',
    lat: '40.5789',
    lng: '22.9485',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Agia Varvara',
    lat: '37.9883',
    lng: '23.6609',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Aigaleo',
    lat: '37.9925',
    lng: '23.6781',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Irakleio',
    lat: '38.0540',
    lng: '23.7745',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'agioi Anargyroi',
    lat: '38.0324',
    lng: '23.7247',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'ilion',
    lat: '38.0323',
    lng: '23.7022',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Keratsini',
    lat: '37.9643',
    lng: '23.6199',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Moschato',
    lat: '37.9540',
    lng: '23.6815',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Zografos',
    lat: '37.9757',
    lng: '23.7691',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Petroúpoli',
    lat: '38.0396',
    lng: '23.6812',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Cholargos',
    lat: '38.0010',
    lng: '23.8008',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Sykies',
    lat: '40.6474',
    lng: '22.9563',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'evosmos',
    lat: '40.6698',
    lng: '22.9088',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'alimos',
    lat: '37.9143',
    lng: '23.7159',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Ilioúpoli',
    lat: '37.9302',
    lng: '23.7514',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Melissia',
    lat: '38.0512',
    lng: '23.8362',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Metamorfosi',
    lat: '38.0639',
    lng: '23.7618',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Maroúsi',
    lat: '38.0537',
    lng: '23.8077',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Argyroúpoli',
    lat: '37.9062',
    lng: '23.7507',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Gerakas',
    lat: '38.0133',
    lng: '23.8560',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Voúla',
    lat: '37.8459',
    lng: '23.7626',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Peraia',
    lat: '40.4990',
    lng: '22.9262',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Zakynthos',
    lat: '37.8000',
    lng: '20.7500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Kifisia',
    lat: '38.0767',
    lng: '23.8208',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Perama',
    lat: '37.9662',
    lng: '23.5739',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'agios Stefanos',
    lat: '38.1408',
    lng: '23.8591',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Aigio',
    lat: '38.2527',
    lng: '22.0820',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Oraiokastro',
    lat: '40.7296',
    lng: '22.9165',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Acharnes',
    lat: '38.0847',
    lng: '23.7372',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'ano Liosia',
    lat: '38.0840',
    lng: '23.7023',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Panorama',
    lat: '40.5853',
    lng: '23.0281',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'arta',
    lat: '39.1601',
    lng: '20.9856',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Gazi',
    lat: '35.3252',
    lng: '25.0662',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Rafina',
    lat: '38.0232',
    lng: '24.0026',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Florina',
    lat: '40.7807',
    lng: '21.4093',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'agios Nikolaos',
    lat: '35.1911',
    lng: '25.7152',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Naxos',
    lat: '37.1021',
    lng: '25.3780',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Ptolemaḯda',
    lat: '40.5121',
    lng: '21.6780',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'edessa',
    lat: '40.8026',
    lng: '22.0475',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'argos',
    lat: '37.6350',
    lng: '22.7285',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Zevgolatio',
    lat: '38.0815',
    lng: '22.6193',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Moires',
    lat: '35.0517',
    lng: '24.8750',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Perama',
    lat: '35.3695',
    lng: '24.7039',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Igoumenitsa',
    lat: '39.5034',
    lng: '20.2673',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Gastoúni',
    lat: '37.8506',
    lng: '21.2534',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Goúrnes',
    lat: '35.3248',
    lng: '25.2776',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Eleoúsa',
    lat: '39.7059',
    lng: '20.7928',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Aspropyrgos',
    lat: '38.0617',
    lng: '23.5894',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Amaliada',
    lat: '37.7972',
    lng: '21.3495',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Koropi',
    lat: '37.9009',
    lng: '23.8723',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Irakleia',
    lat: '41.1823',
    lng: '23.2822',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'argos Orestiko',
    lat: '40.4525',
    lng: '21.2592',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Markopoulo',
    lat: '37.8833',
    lng: '23.9333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Ierapetra',
    lat: '35.0056',
    lng: '25.7333',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Grevena',
    lat: '40.0840',
    lng: '21.4291',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Kalyvia Thorikoú',
    lat: '37.8391',
    lng: '23.9276',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Gerani',
    lat: '35.5168',
    lng: '23.8780',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Mouzaki',
    lat: '39.4288',
    lng: '21.6629',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'andros',
    lat: '37.8362',
    lng: '24.9352',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Karystos',
    lat: '38.0165',
    lng: '24.4204',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Fira',
    lat: '36.4102',
    lng: '25.4056',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Stylida',
    lat: '38.9144',
    lng: '22.6138',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Oropos',
    lat: '38.3195',
    lng: '23.7900',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Agia',
    lat: '39.7188',
    lng: '22.7555',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Mesolongi',
    lat: '38.3714',
    lng: '21.4315',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Ellada',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Palamas',
    lat: '39.4644',
    lng: '22.0825',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'amfissa',
    lat: '38.5281',
    lng: '22.3771',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Loutraki',
    lat: '37.9750',
    lng: '22.9798',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Eretria',
    lat: '38.3971',
    lng: '23.7902',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Peta',
    lat: '39.1677',
    lng: '21.0351',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Sofades',
    lat: '39.3344',
    lng: '22.0973',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Eleftheroúpoli',
    lat: '40.9149',
    lng: '24.2542',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Paros',
    lat: '37.0844',
    lng: '25.1483',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Agia Marina',
    lat: '37.1467',
    lng: '26.8419',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Naousa',
    lat: '40.6300',
    lng: '22.0684',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'evlalo',
    lat: '40.9812',
    lng: '24.8001',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Pyli',
    lat: '39.4596',
    lng: '21.6221',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Gaios',
    lat: '39.1961',
    lng: '20.1858',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Ionia Nisia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Poros',
    lat: '37.5167',
    lng: '23.4667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Chryso',
    lat: '41.0584',
    lng: '23.6514',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Servia',
    lat: '40.1844',
    lng: '22.0005',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Agkistri',
    lat: '37.7096',
    lng: '23.3472',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Farkadona',
    lat: '39.5913',
    lng: '22.0686',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Patmos',
    lat: '37.3086',
    lng: '26.5474',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Nikaia',
    lat: '39.5667',
    lng: '22.4667',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Mandra',
    lat: '38.0747',
    lng: '23.4969',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Sminthi',
    lat: '41.2289',
    lng: '24.8804',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Atalanti',
    lat: '38.6540',
    lng: '22.9970',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Sterea Ellada',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'agioi Deka',
    lat: '35.0582',
    lng: '24.9602',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Nea Zichni',
    lat: '41.0312',
    lng: '23.8289',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Elafonisos',
    lat: '36.5090',
    lng: '22.9787',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Sidirokastro',
    lat: '41.2361',
    lng: '23.3925',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Oinoússes',
    lat: '38.5158',
    lng: '26.2225',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Pramanta',
    lat: '39.5220',
    lng: '21.0986',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Rodolivos',
    lat: '40.9211',
    lng: '23.9746',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kentriki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'ano Viannos',
    lat: '35.0539',
    lng: '25.4100',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Palaiochora',
    lat: '35.2313',
    lng: '23.6813',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Apollonia',
    lat: '36.9744',
    lng: '24.7240',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Agia Kyriaki',
    lat: '39.5226',
    lng: '20.8836',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'ydra',
    lat: '37.3469',
    lng: '23.4657',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Attiki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Asklipieio',
    lat: '37.5975',
    lng: '23.0747',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Peloponnisos',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'iasmos',
    lat: '41.1279',
    lng: '25.1844',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Deskati',
    lat: '39.9253',
    lng: '21.8102',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Agia Foteini',
    lat: '35.2542',
    lng: '24.6342',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Folegandros',
    lat: '36.6222',
    lng: '24.9014',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Vryses',
    lat: '35.4545',
    lng: '23.6004',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Antiparos',
    lat: '37.0404',
    lng: '25.0819',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Anogeia',
    lat: '35.2907',
    lng: '24.8834',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Morfovoúni',
    lat: '39.3525',
    lng: '21.7504',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Thessalia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'ios',
    lat: '36.7233',
    lng: '25.2825',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Kimolos',
    lat: '36.8000',
    lng: '24.5500',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Samothraki',
    lat: '40.4743',
    lng: '25.5251',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Anatoliki Makedonia kai Thraki',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Fry',
    lat: '35.4162',
    lng: '26.9231',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Kythnos',
    lat: '37.3833',
    lng: '24.4167',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Ioulida',
    lat: '37.6397',
    lng: '24.3423',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Chalki',
    lat: '36.2226',
    lng: '27.6113',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Megalo Chorio',
    lat: '37.4578',
    lng: '26.9724',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Asprangeloi',
    lat: '39.8226',
    lng: '20.7276',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Amorgos',
    lat: '36.8318',
    lng: '25.8983',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'ano Kalentini',
    lat: '39.2515',
    lng: '21.1679',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'ipeiros',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Psara',
    lat: '38.5414',
    lng: '25.5630',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Laimos',
    lat: '40.8359',
    lng: '21.1404',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Dytiki Makedonia',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Megalo Chorio',
    lat: '36.4551',
    lng: '27.3469',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Anafi',
    lat: '36.3509',
    lng: '25.7671',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Notio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'agios Efstratios',
    lat: '39.5391',
    lng: '24.9904',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Voreio Aigaio',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Chora Sfakion',
    lat: '35.2018',
    lng: '24.1398',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
  {
    city: 'Kastri',
    lat: '34.8345',
    lng: '24.0864',
    country: 'Greece',
    iso2: 'GR',
    admin_name: 'Kriti',
    capital: 'minor',
    population: '',
    population_proper: '',
  },
];

export { GREEK_CITIES };
