import { Popover } from '@headlessui/react';

export function PopoverConfirm({ buttonText, children }) {
  return (
    <Popover className="relative">
      <Popover.Button>{buttonText}</Popover.Button>

      <Popover.Panel className="absolute z-10">{children}</Popover.Panel>
    </Popover>
  );
}
