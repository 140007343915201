import { TimeSlot } from './';
import useTranslation from 'next-translate/useTranslation';

export function TimeslotsTable({ timeslots, companyId }) {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden sm:table-cell"
                  >
                    <span className="sr-only">Number col</span>
                  </th>
                  <th
                    scope="col"
                    className="relative px-4 py-3 hidden sm:table-cell"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden sm:table-cell"
                  >
                    {t('starts')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden sm:table-cell"
                  >
                    {t('ends')}
                  </th>
                  {companyId !== 'clirf0a6f0000mp08amotqmwf' && (
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden sm:table-cell"
                    >
                      {t('slots')}
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden sm:table-cell"
                  >
                    {t('workout-type')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase hidden sm:table-cell"
                  >
                    {t('info')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {timeslots.map((timeslot, timeslotIdx) => (
                  <TimeSlot
                    key={timeslot.id}
                    timeslot={timeslot}
                    timeslotIdx={timeslotIdx}
                    isLast={timeslots.length === timeslotIdx + 1}
                    companyId={companyId}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
