import { DayName } from '@slotit-io/prisma-schema';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

const colorVariants = {
  Slate: 'border-slate-500 border-b-4 pb-1',
  Gray: 'border-gray-500 border-b-4 pb-1',
  Zinc: 'border-zinc-500 border-b-4 pb-1',
  Neutral: 'border-neutral-500 border-b-4 pb-1',
  Stone: 'border-stone-500 border-b-4 pb-1',
  Red: 'border-red-500 border-b-4 pb-1',
  Orange: 'border-orange-500 border-b-4 pb-1',
  Amber: 'border-amber-500 border-b-4 pb-1',
  Yellow: 'border-yellow-500 border-b-4 pb-1',
  Lime: 'border-lime-500 border-b-4 pb-1',
  Green: 'border-green-500 border-b-4 pb-1',
  Emerald: 'border-emerald-500 border-b-4 pb-1',
  Teal: 'border-teal-500 border-b-4 pb-1',
  Cyan: 'border-cyan-500 border-b-4 pb-1',
  Sky: 'border-sky-500 border-b-4 pb-1',
  Blue: 'border-blue-500 border-b-4 pb-1',
  Indigo: 'border-indigo-500 border-b-4 pb-1',
  Violet: 'border-violet-500 border-b-4 pb-1',
  Purple: 'border-purple-500 border-b-4 pb-1',
  Fuchsia: 'border-fuchsia-500 border-b-4 pb-1',
  Pink: 'border-pink-500 border-b-4 pb-1',
  Rose: 'border-rose-500 border-b-4 pb-1',
};

function CalendarFilters({
  filteredDays,
  setFilteredDays,
  sessionTypes,
  filteredSessionTypes,
  setFilteredSessionTypes,
  setOpenSlideOver,
}) {
  const defaultDays = Object.keys(DayName).map((item) => item);
  async function onSubmit(data) {
    const days = Object.keys(data.defaultDays).filter(
      (day) => data.defaultDays[day]
    );
    const sessionTypes = Object.keys(data.sessionTypes).filter(
      (type) => data.sessionTypes[type]
    );
    setFilteredDays(days);
    setFilteredSessionTypes(sessionTypes);
    setOpenSlideOver(false);
  }

  const { register, handleSubmit } = useForm();

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div>
            <legend className="text-gray-500 text-sm">
              Select workout type
            </legend>
            <fieldset>
              <div className="mt-1 space-x-4 flex">
                {sessionTypes?.map((session) => (
                  <div className="relative flex items-end" key={session.name}>
                    <div className="mt-1 flex flex-wrap gap-4">
                      <input
                        id={session.name}
                        name={session.name}
                        type={'checkbox'}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mt-0.5"
                        defaultChecked={
                          filteredSessionTypes.length > 0
                            ? filteredSessionTypes?.includes(session.name)
                            : true
                        }
                        {...register(`sessionTypes.${session.name}`)}
                      />
                    </div>
                    <div className="ml-1 text-sm">
                      <label
                        htmlFor={session.name}
                        className={clsx({
                          'font-medium text-gray-700': true,
                          [colorVariants[session?.color]]: true,
                        })}
                      >
                        {session.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          <div>
            <legend className="text-gray-500 text-sm">Select day</legend>
            <fieldset>
              <div className="mt-1 flex flex-wrap gap-4">
                {defaultDays.map((day) => (
                  <div className="relative flex" key={day}>
                    <div className="flex items-center">
                      <input
                        id={day}
                        name={day}
                        type={'checkbox'}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mt-0.5"
                        defaultChecked={
                          filteredDays.length > 0
                            ? filteredDays?.includes(day)
                            : true
                        }
                        {...register(`defaultDays.${day}`)}
                      />
                    </div>
                    <div className="ml-1 text-sm">
                      <label
                        htmlFor={day}
                        className="font-medium text-gray-700"
                      >
                        {day}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex w-full">
            <button
              type={'submit'}
              className={clsx({
                'inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2':
                  true,
                'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500':
                  true,
              })}
            >
              Apply filters
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export { CalendarFilters };
