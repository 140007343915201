import axios from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';

export const axiosInst = axios.create({
  baseURL: '/api',
});

axiosInst.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      return Promise.resolve();
    }

    if (error?.response?.status >= 500) {
      Sentry.captureException(error);
    }

    if (error?.response && error?.response?.data) {
      toast.error(
        error?.response?.data?.error?.message || error?.response?.data?.message
      );

      return Promise.reject(error?.response?.data);
    }
    return Promise.reject(error?.message);
  }
);
