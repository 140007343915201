import { useForm } from 'react-hook-form';

function AssignSubscriptionPlan({ plan, sendSubscriptionRequest }) {
  // const today = new Date();
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      // startsAt: format(today, 'Y-MM-dd'),
      // endsAt: format(addDays(today, parseInt(plan?.duration)), 'Y-MM-dd'),
      planId: plan.id,
    },
  });

  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     if (name === 'startsAt' && value.startsAt) {
  //       setValue(
  //         'endsAt',
  //         format(
  //           addDays(new Date(value.startsAt), parseInt(plan?.duration)),
  //           'Y-MM-dd'
  //         )
  //       );
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  return (
    <form onSubmit={handleSubmit(sendSubscriptionRequest)}>
      {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
        <div className="sm:col-span-2">
          <label
            htmlFor="startsAt"
            className="block text-sm font-medium text-gray-700"
          >
            Starts At
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="date"
              name="startsAt"
              id="startsAt"
              autoComplete="startsAt"
              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
              {...register('startsAt')}
              required
            />
          </div>
          <p className="mt-1 text-sm text-gray-500">
            Select the date your subscription plan starts.
          </p>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="endsAt"
            className="block text-sm font-medium text-gray-700"
          >
            Ends At
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="date"
              name="endsAt"
              id="endsAt"
              autoComplete="endsAt"
              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 bg-gray-100"
              {...register('endsAt')}
              required
              disabled
            />
          </div>
          <p className="mt-1 text-sm text-gray-500">
            Your subscription plan ends at.
          </p>
        </div>
      </div> */}
      <div className="mt-8 flex justify-center">
        <button
          type="submit"
          className="w-full max-w-xs flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:text-lg"
        >
          Send Request
        </button>
      </div>
    </form>
  );
}

export { AssignSubscriptionPlan };
