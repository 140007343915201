import { Popover } from '@headlessui/react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { useState } from 'react';
import Spinner from '../public/images/spinner.svg';
import { el } from 'date-fns/locale';
import useTranslation from 'next-translate/useTranslation';
import { ReservationStatus, Timeslot } from '@slotit-io/prisma-schema';
import { axiosInst, dateWithTz } from '../helpers';
import clsx from 'clsx';

export const colorVariants = {
  Slate: 'border-slate-500 border-l-4 pl-3',
  Gray: 'border-gray-500 border-l-4 pl-3',
  Zinc: 'border-zinc-500 border-l-4 pl-3',
  Neutral: 'border-neutral-500 border-l-4 pl-3',
  Stone: 'border-stone-500 border-l-4 pl-3',
  Red: 'border-red-500 border-l-4 pl-3',
  Orange: 'border-orange-500 border-l-4 pl-3',
  Amber: 'border-amber-500 border-l-4 pl-3',
  Yellow: 'border-yellow-500 border-l-4 pl-3',
  Lime: 'border-lime-500 border-l-4 pl-3',
  Green: 'border-green-500 border-l-4 pl-3',
  Emerald: 'border-emerald-500 border-l-4 pl-3',
  Teal: 'border-teal-500 border-l-4 pl-3',
  Cyan: 'border-cyan-500 border-l-4 pl-3',
  Sky: 'border-sky-500 border-l-4 pl-3',
  Blue: 'border-blue-500 border-l-4 pl-3',
  Indigo: 'border-indigo-500 border-l-4 pl-3',
  Violet: 'border-violet-500 border-l-4 pl-3',
  Purple: 'border-purple-500 border-l-4 pl-3',
  Fuchsia: 'border-fuchsia-500 border-l-4 pl-3',
  Pink: 'border-pink-500 border-l-4 pl-3',
  Rose: 'border-rose-500 border-l-4 pl-3',
};

const TimeSlot = ({ timeslot, timeslotIdx, isLast, companyId }) => {
  const [loading, setLoading] = useState(false);
  const [timeslotData, setTimeslotData] = useState(timeslot);

  function isFullyBooked(slot: any) {
    return slot._count.reservations >= Number(slot.maxCapacity);
  }

  function reservationsInWaitingListLength(slot) {
    return (
      slot?.reservations.filter(
        (res) => res.status === ReservationStatus.WaitingList
      ).length > 0
    );
  }

  async function handleBookAction(timeslot: Timeslot, close) {
    setLoading(true);
    try {
      const { data } = await axiosInst.patch(
        `/reservations/${timeslotData.id}`,
        {
          status: isFullyBooked(timeslot)
            ? ReservationStatus.WaitingList
            : ReservationStatus.Active,
        }
      );

      setTimeslotData(data.timeslot);
      close();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCancelBookAction(timeslot, close) {
    setLoading(true);
    try {
      const { data } = await axiosInst.patch(
        `/reservations/${timeslotData.id}`,
        {
          status: ReservationStatus.Cancelled,
        }
      );
      setTimeslotData(data.timeslot);
      close();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  const { t, lang } = useTranslation('common');

  return (
    <tr
      id={timeslotData.id}
      key={timeslotData.id}
      className={clsx({
        'bg-white':
          timeslotIdx % 2 === 0 &&
          !timeslotData.validations?.IS_ALREADY_BOOKED_BY_USER &&
          !timeslotData.validations?.IS_USER_JOINED_WAITLIST,
        'bg-gray-50':
          timeslotIdx % 2 === 1 &&
          !timeslotData.validations?.IS_ALREADY_BOOKED_BY_USER &&
          !timeslotData.validations?.IS_USER_JOINED_WAITLIST,
        'bg-green-50': timeslotData.validations?.IS_ALREADY_BOOKED_BY_USER,
        'bg-yellow-50': timeslotData.validations?.IS_USER_JOINED_WAITLIST,
      })}
    >
      <td className="px-4 py-3 text-sm font-medium text-gray-500 hidden sm:table-cell">
        <>
          {timeslotData.validations?.IS_ALREADY_BOOKED_BY_USER ? (
            <div className="flex -ml-1">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              {/* <span className="text-sm text-green-500">Booked</span> */}
            </div>
          ) : (
            <span>{timeslotIdx + 1}.</span>
          )}
        </>
      </td>
      <td className="px-4 py-3 text-sm font-medium">
        <div
          className={
            colorVariants[timeslotData.sessionType?.color] ||
            'border-transparent border-l-4 pl-3'
          }
        >
          <div className="block sm:hidden mb-2">
            <div className="flex">
              <>
                {timeslotData.validations?.IS_ALREADY_BOOKED_BY_USER && (
                  <div className="flex -ml-1 mr-1">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    {/* <span className="text-sm text-green-500">Booked</span> */}
                  </div>
                )}
              </>
              {/* <span className="pr-3 text-left text-xs font-medium text-gray-500 uppercase inline-block py-0.5">
                {t('date')}
              </span> */}
              {format(dateWithTz(timeslotData.startsAt), 'HH:mm')}-
              {format(
                dateWithTz(timeslotData.endsAt),
                'HH:mm | EEE dd LLL',
                lang === 'el' && {
                  locale: el,
                }
              )}
            </div>
            <div className="space-x-2">
              <span>{timeslotData.sessionType?.name}</span>
              <span>{timeslotData.info}</span>
            </div>
            <div>
              {companyId !== 'clirf0a6f0000mp08amotqmwf' && (
                <span className="pr-3 text-left text-xs font-medium text-gray-500 uppercase inline-block py-0.5">
                  {t('slots')}
                </span>
              )}
              {companyId === 'clirf0a6f0000mp08amotqmwf'
                ? ''
                : `${timeslotData._count.reservations}/${timeslotData.maxCapacity}`}
              {reservationsInWaitingListLength(timeslotData) && (
                <span>
                  (
                  {
                    timeslotData?.reservations.filter(
                      (res) => res.status === ReservationStatus.WaitingList
                    ).length
                  }{' '}
                  in waiting list)
                </span>
              )}
            </div>
          </div>
          {loading ? (
            <>
              <button
                disabled
                type="button"
                className="w-24 sm:w-auto inline-flex items-center justify-center px-2.5 py-2.5 sm:py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white opacity-50 focus:ring-indigo-500"
              >
                <Spinner className="animate-spin h-4 w-4 mr-2" />
                {timeslotData.validations?.IS_ALREADY_BOOKED_BY_USER ||
                timeslotData.validations?.IS_USER_JOINED_WAITLIST
                  ? 'Cancel'
                  : 'Book'}
              </button>
            </>
          ) : (
            <div>
              {timeslotData.validations?.IS_PAST_DATE ||
              !timeslotData.validations?.IS_WEEK_ENABLED ? (
                timeslotData.validations?.IS_ALREADY_BOOKED_BY_USER ||
                timeslotData.validations?.IS_USER_JOINED_WAITLIST ? (
                  <button
                    disabled
                    type="button"
                    className="w-24 sm:w-auto inline-flex items-center justify-center px-2.5 py-2.5 sm:py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white opacity-50 focus:ring-indigo-500"
                  >
                    {timeslotData.validations?.IS_USER_JOINED_WAITLIST
                      ? t('remove-from-list')
                      : t('cancel')}
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className="w-24 sm:w-auto inline-flex items-center justify-center px-2.5 py-2.5 sm:py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white opacity-50 focus:ring-indigo-500"
                  >
                    {t('book')}
                  </button>
                )
              ) : timeslotData.validations?.IS_ALREADY_BOOKED_BY_USER ||
                timeslotData.validations?.IS_USER_JOINED_WAITLIST ? (
                <Popover className="relative">
                  {({ close }) => (
                    <>
                      <Popover.Button className="w-auto sm:w-auto inline-flex items-center justify-center px-2.5 py-2.5 sm:py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                        {timeslotData.validations?.IS_USER_JOINED_WAITLIST
                          ? t('remove-from-list')
                          : t('cancel')}
                      </Popover.Button>
                      <Popover.Panel
                        className={`bg-white absolute z-10 w-72 sm:w-screen sm:max-w-xs sm:px-0 left-0 ${
                          isLast ? 'bottom-0' : 'top-0'
                        }`}
                      >
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="p-4">
                            <div className="flex items-start">
                              <div className="ml-3 w-0 flex-1 text-left">
                                <p className="text-sm font-medium text-gray-900">
                                  {timeslotData.validations
                                    ?.IS_USER_JOINED_WAITLIST
                                    ? t('remove-from-list')
                                    : t('cancel')}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {t('release-slot')}
                                </p>
                                <div className="mt-4 flex">
                                  <button
                                    onClick={() =>
                                      handleCancelBookAction(timeslot, close)
                                    }
                                    type="button"
                                    className="w-auto sm:w-auto inline-flex items-center justify-center px-2.5 py-2.5 sm:py-1.5 border border-red-300 shadow-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  >
                                    {timeslotData.validations
                                      ?.IS_USER_JOINED_WAITLIST
                                      ? t('remove-from-list')
                                      : t('cancel')}
                                  </button>
                                </div>
                              </div>
                              <div className="ml-4 flex-shrink-0 flex">
                                <button
                                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  onClick={() => close()}
                                >
                                  <span className="sr-only">Close</span>
                                  <XMarkIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              ) : (
                <>
                  <Popover className="relative">
                    {({ close }) => (
                      <>
                        <Popover.Button
                          className={clsx({
                            'w-24 sm:w-auto inline-flex items-center justify-center px-2.5 py-2.5 sm:py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2':
                              true,
                            'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500':
                              !isFullyBooked(timeslotData),
                            'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400':
                              isFullyBooked(timeslotData),
                          })}
                        >
                          {isFullyBooked(timeslotData)
                            ? t('join-list')
                            : t('book')}
                        </Popover.Button>
                        <Popover.Panel
                          className={`bg-white absolute z-10 w-72 sm:w-screen sm:max-w-xs sm:px-0 left-0 ${
                            isLast ? 'bottom-0' : 'top-0'
                          }`}
                        >
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                              <div className="flex items-start">
                                <div className="ml-3 w-0 flex-1 text-left">
                                  <p className="text-sm font-medium text-gray-900 ">
                                    {isFullyBooked(timeslotData)
                                      ? t('join-list')
                                      : t('book')}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500 text-wrap">
                                    {isFullyBooked(timeslotData)
                                      ? t('join-waiting-list-for-that-slot')
                                      : t('book-that-slot')}
                                  </p>
                                  <div className="mt-4 flex">
                                    <button
                                      onClick={() =>
                                        handleBookAction(timeslot, close)
                                      }
                                      type="button"
                                      className={clsx({
                                        'w-24 sm:w-auto inline-flex items-center justify-center px-2.5 py-2.5 sm:py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2':
                                          true,
                                        'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500':
                                          !isFullyBooked(timeslotData),
                                        'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400':
                                          isFullyBooked(timeslotData),
                                      })}
                                    >
                                      {isFullyBooked(timeslotData)
                                        ? t('join-list')
                                        : t('book')}
                                    </button>
                                  </div>
                                </div>
                                <div className="ml-4 flex-shrink-0 flex">
                                  <button
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => close()}
                                  >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </Popover>
                </>
              )}
            </div>
          )}
        </div>
      </td>
      <td className="px-4 py-3 text-sm font-medium text-gray-900 hidden sm:table-cell">
        {format(dateWithTz(timeslotData.startsAt), 'HH:mm')}
      </td>
      <td className="px-4 py-3 text-sm text-gray-900 hidden sm:table-cell">
        {format(dateWithTz(timeslotData.endsAt), 'HH:mm')}
      </td>
      <td className="px-4 py-3 text-sm text-gray-900 hidden sm:table-cell">
        {companyId === 'clirf0a6f0000mp08amotqmwf'
          ? ''
          : `${timeslotData._count.reservations}/${timeslotData.maxCapacity}`}
        {reservationsInWaitingListLength(timeslotData) && (
          <span>
            (
            {
              timeslotData?.reservations.filter(
                (res) => res.status === ReservationStatus.WaitingList
              ).length
            }{' '}
            in waiting list)
          </span>
        )}
      </td>
      <td className="px-4 py-3 text-sm text-gray-900 hidden sm:table-cell">
        {timeslotData.sessionType?.name}
      </td>
      <td className="px-4 py-3 text-sm text-gray-900 hidden sm:table-cell">
        {timeslotData.info}
      </td>
    </tr>
  );
};

export { TimeSlot };
