import Spinner from '../public/images/spinner.svg';

function Loader() {
  return (
    <div className="fixed inset-0 flex justify-center">
      <div className="w-full h-full z-10 absolute bg-gray-50 bg-opacity-25"></div>
      <div className="mt-12 flex items-center z-20">
        <Spinner className="animate-spin -ml-1 mr-3 h-12 w-12 text-indigo-600" />
      </div>
    </div>
  );
}

export { Loader };
