import React, { Fragment, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { axiosInst } from '../helpers';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { ReservationStatus } from '@slotit-io/prisma-schema';

const orderByOptions = [
  {
    name: 'Closest',
    href: '#',
    value: {
      orderBy: {
        timeslot: {
          startsAt: 'asc',
        },
      },
    },
    current: false,
  },
  {
    name: 'Newest',
    href: '#',
    value: {
      orderBy: {
        timeslot: {
          startsAt: 'desc',
        },
      },
    },
    current: false,
  },
];

export function ReservationsList({ selectedCustomer, status, subscriptionId }) {
  const [customerReservations, setCustomerReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderByOption, setOrderByOption] = useState(orderByOptions[0].value);

  const [reservationStatus, setReservationStatus] =
    useState<ReservationStatus[]>(status);

  const componentRef = useRef<any>();

  useEffect(() => {
    const filters = {
      timeslotOrderBy: orderByOption,
      status: reservationStatus.join(','),
      subscriptionId,
    };
    async function fetchData() {
      const response = await fetchReservations(filters);
    }
    fetchData();
  }, [orderByOption, reservationStatus]);

  function handleCheckBox(event) {
    if (event.target.checked) {
      setReservationStatus([...reservationStatus, event.target.name]);
    } else {
      setReservationStatus(
        reservationStatus.filter((item) => item !== event.target.name)
      );
    }
  }

  if (!customerReservations) {
    return <>there are no reservations yet</>;
  }

  async function fetchReservations(filter) {
    try {
      const { data } = await axiosInst.get(`reservations/customer`, {
        params: filter,
      });
      setCustomerReservations(data);
    } catch (error) {}
  }

  return (
    <div ref={componentRef}>
      <div className="pb-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Reservations
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {selectedCustomer?.firstname || selectedCustomer?.user?.firstname}{' '}
          {selectedCustomer?.lastname || selectedCustomer?.user?.lastname}{' '}
          reservations details and info.
        </p>
      </div>
      <div className="pb-6 z-10 relative">
        <div className="flex items-end max-w-xl">
          <div className="flex-1">
            <div className="relative flex flex-col sm:flex-row items-start space-y-2 sm:space-y-0 sm:space-x-6">
              <div className="flex items-center">
                <div className="flex items-center h-5">
                  <input
                    id="old-reservations"
                    aria-describedby="old-reservations"
                    name={ReservationStatus.Expired}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    onChange={(event) => handleCheckBox(event)}
                    checked={reservationStatus.includes(
                      ReservationStatus.Expired
                    )}
                  />
                </div>
                <div className="ml-2 text-sm">
                  <label
                    htmlFor="old-reservations"
                    className="font-medium text-gray-700"
                  >
                    Past reservations
                  </label>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex items-center h-5">
                  <input
                    id="cancelled-reservations"
                    aria-describedby="cancelled-reservations"
                    name={ReservationStatus.Cancelled}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    onChange={(event) => handleCheckBox(event)}
                  />
                </div>
                <div className="ml-2 text-sm">
                  <label
                    htmlFor="cancelled-reservations"
                    className="font-medium text-gray-700"
                  >
                    Cancelled reservations
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Order
                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {orderByOptions.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <a
                            href={option.href}
                            className={clsx({
                              'font-medium text-gray-900':
                                JSON.stringify(option.value) ===
                                JSON.stringify(orderByOption),
                              'text-gray-500':
                                JSON.stringify(option.value) !==
                                JSON.stringify(orderByOption),
                              'bg-gray-100': active,
                              'block px-4 py-2 text-sm': true,
                            })}
                            onClick={(event: any) => {
                              event.preventDefault();
                              setOrderByOption(option.value);
                            }}
                          >
                            {option.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell"
              >
                <span className="sr-only">No.</span>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Details
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {customerReservations.map((reservation, reservationIdx) => (
              <tr
                key={reservation.timeslot.id}
                className={reservationIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
              >
                <td className="px-6 py-4 text-sm font-medium text-gray-500 hidden lg:table-cell">
                  {reservationIdx + 1}.
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  <div className="space-y-1 lg:space-y-0">
                    <div>
                      {format(
                        new Date(reservation.timeslot.startsAt),
                        'EEE dd LLL yyyy | HH:mm'
                      )}
                      -{format(new Date(reservation.timeslot.endsAt), 'HH:mm')}
                    </div>
                    <div className="flex items:center space-x-1 lg:hidden">
                      <span className="text-sm text-gray-400">Status</span>
                      <span>{reservation.status}</span>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 hidden lg:table-cell">
                  {reservation.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
