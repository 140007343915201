import { XMarkIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import {
  calculateDiffInDays,
  TODAY,
  SUBSCRIPTION_END_DAYS_DIFF,
} from '../helpers';

export enum SubscriptionWarningType {
  Active = 'Active',
  Expired = 'Expired',
}

function SubscriptionWarning({
  subscription,
  company,
  subscriptionEndsWarning,
  handleSubscriptionEndsWarning,
  dismissable = true,
}) {
  const subscriptionPlanEnds = (subscription) =>
    calculateDiffInDays(subscription.endsAt, TODAY);

  // if (
  //   subscriptionEndsWarning &&
  //   subscription.status === SubscriptionWarningType.Active &&
  //   subscription.sessions &&
  //   subscription.sessions - subscription.reservationsCount <= 1
  // ) {
  //   return (
  //     <>
  //       <div className="bg-yellow-50 rounded-md p-4 fixed sm:relative z-10 sm:z-0 w-full mb-6 -ml-4 sm:ml-0">
  //         <div className="flex">
  //           <div className="flex-shrink-0">
  //             <ExclamationTriangleIcon
  //               className="h-5 w-5 text-yellow-400"
  //               aria-hidden="true"
  //             />
  //           </div>
  //           <div className="ml-3 flex flex-col space-y-4 md:space-y-0">
  //             <p className="text-sm text-yellow-700 mr-4">
  //               There is only one available session to book in your subscription
  //               plan, please{' '}
  //               <a
  //                 className="font-medium underline hover:text-yellow-600"
  //                 href={`tel:${company?.user?.phoneNumber}`}
  //               >
  //                 contact us
  //               </a>{' '}
  //               to reniew it.
  //             </p>
  //           </div>
  //           {dismissable && (
  //             <div className="ml-auto pl-3">
  //               <div className="-mx-1.5 -my-1.5">
  //                 <button
  //                   type="button"
  //                   onClick={() => handleSubscriptionEndsWarning(subscription)}
  //                   className="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
  //                 >
  //                   <span className="sr-only">Dismiss</span>
  //                   <XMarkIcon className="h-5 w-5" aria-hidden="true" />
  //                 </button>
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  if (
    subscriptionEndsWarning &&
    subscription.status === SubscriptionWarningType.Expired
  ) {
    return (
      <>
        <div className="bg-red-50 rounded-md p-4 fixed sm:relative z-10 sm:z-0 w-full mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex flex-col space-y-4 md:space-y-0">
              <p className="text-sm text-red-700 mr-4">
                There is no available session to book in your subscription plan,
                please{' '}
                <a
                  className="font-medium underline hover:text-red-600"
                  href={`tel:${company?.user?.phoneNumber}`}
                >
                  contact us
                </a>{' '}
                to reniew it.
              </p>
            </div>
            {dismissable && (
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    onClick={() => handleSubscriptionEndsWarning(subscription)}
                    className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-yellow-600"
                  >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  if (subscription.status === SubscriptionWarningType.Active) {
    return (
      <div>
        {subscriptionEndsWarning &&
          subscriptionPlanEnds(subscription) > 0 &&
          subscriptionPlanEnds(subscription) <= SUBSCRIPTION_END_DAYS_DIFF && (
            <>
              <div className="bg-yellow-50 rounded-md p-4 fixed sm:relative z-10 sm:z-0 w-full mb-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex flex-col space-y-4 md:space-y-0">
                    <p className="text-sm text-yellow-700 mr-4">
                      Your subscription plan ends in{' '}
                      {subscriptionPlanEnds(subscription)} day/s, please{' '}
                      <a
                        className="font-medium underline hover:text-yellow-600"
                        href={`tel:${company?.user?.phoneNumber}`}
                      >
                        contact us
                      </a>{' '}
                      to reniew it.
                    </p>
                  </div>
                  {dismissable && (
                    <div className="ml-auto pl-3">
                      <div className="-mx-1.5 -my-1.5">
                        <button
                          type="button"
                          onClick={() =>
                            handleSubscriptionEndsWarning(subscription)
                          }
                          className="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
                        >
                          <span className="sr-only">Dismiss</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
      </div>
    );
  }

  if (subscription.status === SubscriptionWarningType.Expired) {
    return (
      <>
        {subscriptionEndsWarning &&
          subscriptionPlanEnds(subscription) <= 0 &&
          subscriptionPlanEnds(subscription) * -1 <=
            SUBSCRIPTION_END_DAYS_DIFF && (
            <>
              <div className="bg-red-50 rounded-md p-4 fixed sm:relative z-10 sm:z-0 w-full mb-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex flex-col space-y-4 md:space-y-0">
                    <p className="text-sm text-red-700 mr-4">
                      Your subscription plan has ended, please{' '}
                      <a
                        className="font-medium underline hover:text-red-600"
                        href={`tel:${company?.user?.phoneNumber}`}
                      >
                        contact us
                      </a>{' '}
                      to reniew it.
                    </p>
                  </div>
                  {dismissable && (
                    <div className="ml-auto pl-3">
                      <div className="-mx-1.5 -my-1.5">
                        <button
                          type="button"
                          onClick={() =>
                            handleSubscriptionEndsWarning(subscription)
                          }
                          className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-yellow-600"
                        >
                          <span className="sr-only">Dismiss</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
      </>
    );
  }

  return <></>;
}

export { SubscriptionWarning };
