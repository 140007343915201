export enum PlanStatus {
  Active = 'Active',
  Disabled = 'Disabled',
}

export function SubscriptionPlanCard({
  plan,
  theme,
  children,
}: {
  plan: any;
  theme?: string;
  children?: any;
}) {
  return (
    <div
      key={plan.name}
      className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
    >
      <div className="p-6 flex flex-col h-full">
        <div className="flex flex-col flex-1">
          <div className="flex flex-1 flex-col">
            <div className="flex">
              <div className="flex flex-1 space-x-2">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {plan.name}
                </h3>
              </div>
            </div>
            <p className="mt-4 mb-6 text-sm text-gray-500">{plan.info}</p>
          </div>
          <p>
            {plan.duration && (
              <>
                <span className="text-4xl font-bold text-gray-900">
                  {plan.duration}{' '}
                </span>
                <span className="text-lg">
                  {plan.duration > 1 ? 'days' : 'day'}
                </span>
              </>
            )}
          </p>
          <p>
            {plan.sessions && (
              <>
                <span className="mt-4 text-4xl font-bold text-gray-900">
                  {plan.sessions}{' '}
                </span>
                <span className="text-lg">
                  {plan.sessions > 1 ? 'sessions' : 'session'}
                </span>
                {plan.maxSessionsPerWeek && (
                  <span className="text-sm text-gray-500 pl-1">
                    ({plan.maxSessionsPerWeek}/week)
                  </span>
                )}
              </>
            )}
          </p>
          <p className="mt-1 text-4xl font-bold text-gray-900">{plan.price}€</p>
          <div className="mt-8 text-right">{children}</div>
        </div>
      </div>
    </div>
  );
}
