import { getISODay, add, differenceInCalendarDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { enGB } from 'date-fns/locale';

export const DAY_NAME = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export function getDateOfISOWeek(week: number, year: number) {
  const simple = dateWithTz(new Date(year, 0, 1 + (week - 1) * 7));
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }
  return ISOweekStart;
}

export function createDateTimeFromWeekDay({ weekName, dayName }): Date {
  const [year, week] = weekName.split('-W');

  const dateOfISOWeek = getDateOfISOWeek(parseInt(week), parseInt(year));

  return add(dateOfISOWeek, {
    days: DAY_NAME[dayName] - getISODay(dateOfISOWeek),
  });
}

export function createDateTimeFromWeekDayHour({
  weekName,
  dayName,
  time,
}): Date {
  const [year, week] = weekName.split('-W');
  const [hours, minutes] = time.split(':');

  const dateOfISOWeek = getDateOfISOWeek(parseInt(week), parseInt(year));

  return add(dateOfISOWeek, {
    days: DAY_NAME[dayName] - getISODay(dateOfISOWeek),
    hours,
    minutes,
  });
}

export const MONTHS = [];

export const YEARS_WITH_MONTHS = [2022];

YEARS_WITH_MONTHS.map((year, index) => ({
  ...new Array(12).fill(1).map((item, index) =>
    MONTHS.push({
      name: enGB.localize.month(index),
      key: index + 1,
      year,
    })
  ),
}));

export const SUBSCRIPTION_END_DAYS_DIFF = 5;
export const TODAY = dateWithTz();

export function calculateDiffInDays(endsAt, today) {
  if (!endsAt) {
    return 0;
  }

  const endsAtDate = dateWithTz(endsAt);

  return differenceInCalendarDays(endsAtDate, today);
}

export function dateWithTz(
  date: Date | string = new Date(),
  timezone = 'Europe/Athens'
) {
  return utcToZonedTime(date, timezone);
}
