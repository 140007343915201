import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  TableCellsIcon,
} from '@heroicons/react/24/solid';
import {
  Reservation,
  ReservationStatus,
  SubscriptionStatus,
} from '@slotit-io/prisma-schema';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { dateWithTz } from '../helpers';
import { Modal } from './Modal';
import { ReservationsList } from './ReservationsList';

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function SubscriptionBadge({ status }) {
  return (
    <span
      className={clsx({
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium':
          true,
        'bg-yellow-100 text-yellow-800':
          status === SubscriptionStatus.Pending ||
          status === SubscriptionStatus.Paused,
        'bg-red-100 text-red-800':
          status === SubscriptionStatus.Expired ||
          status === SubscriptionStatus.Cancelled,
        'bg-green-100 text-green-800': status === SubscriptionStatus.Active,
      })}
    >
      {status}
    </span>
  );
}

export function SubscriptionCard({ subscription, customer, children }) {
  const { register, handleSubmit, setValue, watch, getValues } = useForm();
  const [showLogs, setShowLogs] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reservationStatus, setReservationStatus] = useState<
    ReservationStatus[]
  >([ReservationStatus.Active, ReservationStatus.Expired]);

  const groupedReservations = groupBy(
    subscription?.reservations,
    (reservation: Reservation) => reservation.status
  );

  function calculatePaidAmount(payments) {
    return payments?.reduce(
      (partialSum, payment) => partialSum + parseInt(payment.amount, 0),
      0
    );
  }

  const start = dateWithTz(subscription.startsAt);
  const end = subscription.endsAt ? dateWithTz(subscription.endsAt) : null;

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div>
          <div className="px-4 py-5 flex">
            <div className="flex flex-1 space-x-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {JSON.parse(subscription.planData).name}
              </h3>
              <div>
                <SubscriptionBadge status={subscription.status} />
              </div>
            </div>
          </div>
          {children}
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Starts At</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                <div className="flex rounded-md shadow-sm">
                  {format(start, 'dd/LL/yyyy')}
                </div>
              </dd>
            </div>
            {end && (
              <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Ends At</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                  <div className="flex rounded-md shadow-sm">
                    {format(end, 'dd/LL/yyyy')}
                  </div>
                </dd>
              </div>
            )}
            {subscription?.sessions && (
              <>
                <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    Sessions
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                    {/* <div className="flex space-x-4">
                      <div>
                        {subscription?.sessions}{' '}
                        <span className="text-gray-500">Total</span>
                      </div>
                      <div>
                        {subscription._count.reservations}{' '}
                        <span className="text-gray-500">Booked</span>
                      </div>
                      <div>
                        {subscription.sessions -
                          subscription._count.reservations}{' '}
                        <span className="text-gray-500">Remaining to book</span>
                      </div>
                    </div> */}
                    <div className="flex space-x-4">
                      {subscription?.sessions && (
                        <div>
                          {subscription?.sessions}{' '}
                          <span className="text-gray-500">Total</span>
                        </div>
                      )}
                      <div>
                        <a
                          onClick={() => {
                            setOpenModal(true);
                            setReservationStatus([
                              ReservationStatus.Active,
                              ReservationStatus.Expired,
                            ]);
                          }}
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        >
                          {subscription?._count.reservations}{' '}
                          <span>Booked</span>
                        </a>
                      </div>
                      <div>
                        <a
                          onClick={() => {
                            setOpenModal(true);
                            setReservationStatus([ReservationStatus.Active]);
                          }}
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        >
                          {groupedReservations.get(ReservationStatus.Active)
                            ?.length || 0}{' '}
                          <span>Pending</span>
                        </a>
                      </div>
                      <div>
                        <a
                          onClick={() => {
                            setOpenModal(true);
                            setReservationStatus([ReservationStatus.Expired]);
                          }}
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        >
                          {groupedReservations.get(ReservationStatus.Expired)
                            ?.length || 0}{' '}
                          <span>Expired</span>
                        </a>
                      </div>
                    </div>
                  </dd>
                </div>
              </>
            )}
            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Amount</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                <div className="flex space-x-4">
                  <div>
                    {calculatePaidAmount(subscription.payments)}€{' '}
                    <span className="text-gray-500">Paid</span>
                  </div>
                  <div>
                    {JSON.parse(subscription.planData).price -
                      calculatePaidAmount(subscription.payments)}
                    € <span className="text-gray-500">Pending</span>
                  </div>
                </div>
              </dd>
            </div>

            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Payment history
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                {subscription.payments.length === 0 ? (
                  <p className="text-sm text-gray-500">
                    There are no payments yet.
                  </p>
                ) : (
                  <ul
                    role="list"
                    className="border border-gray-200 rounded-md divide-y divide-gray-200"
                  >
                    {subscription.payments?.map((payment) => (
                      <li
                        key={payment.id}
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <CheckCircleIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="mx-2 flex-shrink-0">
                            <p className="font-medium">{payment.amount}€</p>
                          </div>
                          <span className="flex-1 w-0 truncate">
                            {format(
                              dateWithTz(payment.createdAt),
                              'dd/LL/yy HH:mm'
                            )}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </dd>
            </div>
            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                {' '}
                <a
                  className="flex flex-1 text-indigo-600 hover:text-indigo-900 cursor-pointer"
                  onClick={() => setShowLogs(!showLogs)}
                >
                  <span className="flex-1">Logs</span>
                  {showLogs ? (
                    <ChevronUpIcon
                      className="flex-shrink-0 h-5 w-5 "
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronDownIcon
                      className="flex-shrink-0 h-5 w-5 "
                      aria-hidden="true"
                    />
                  )}
                </a>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                <ul
                  role="list"
                  className="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  {showLogs ? (
                    subscription.subscriptionLogs?.map((log) => (
                      <li
                        key={log.id}
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <TableCellsIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="mx-2 flex-shrink-0">
                            <p className="font-medium">
                              {JSON.stringify(log.transitions.message)}
                            </p>
                          </div>
                          <span className="flex-1 w-0 truncate">
                            {format(
                              dateWithTz(log.createdAt),
                              'dd/LL/yy HH:mm'
                            )}
                          </span>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li
                      key={subscription.subscriptionLogs[0].id}
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    >
                      <div className="w-0 flex-1 flex items-center">
                        <TableCellsIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <div className="mx-2 flex-shrink-0">
                          <p className="font-medium">
                            {JSON.stringify(
                              subscription.subscriptionLogs[0].transitions
                                .message
                            )}
                          </p>
                        </div>
                        <span className="flex-1 w-0 truncate">
                          {format(
                            dateWithTz(
                              subscription.subscriptionLogs[0].createdAt
                            ),
                            'dd/LL/yy HH:mm'
                          )}
                        </span>
                      </div>
                    </li>
                  )}
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        content={
          <ReservationsList
            selectedCustomer={customer}
            status={reservationStatus}
            subscriptionId={subscription.id}
          />
        }
        small
      />
    </>
  );
}
