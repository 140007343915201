/* eslint-disable @next/next/no-html-link-for-pages */
import React, { ReactNode } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { Fragment, useState } from 'react';
import Link from 'next/link';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  QueueListIcon,
  CalendarIcon,
  Bars3BottomLeftIcon,
  CogIcon,
  XMarkIcon,
  ClockIcon,
  CheckBadgeIcon,
  BuildingOffice2Icon,
} from '@heroicons/react/24/solid';
import { useUser } from '@auth0/nextjs-auth0';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTranslation from 'next-translate/useTranslation';

type Props = {
  children?: ReactNode;
  title?: string;
  company?: any;
};

const navigation = [
  { name: 'calendar', href: '/calendar', icon: CalendarIcon, current: true },
  {
    name: 'reservations',
    href: '/reservations',
    icon: QueueListIcon,
    current: true,
  },
  {
    name: 'subscriptions',
    href: '/subscriptions',
    icon: CheckBadgeIcon,
    current: false,
  },
  { name: 'profile', href: '/profile', icon: CogIcon, current: false },
  {
    name: 'company',
    href: '/company',
    icon: BuildingOffice2Icon,
    current: false,
  },
];

const userNavigation = [
  { name: 'profile', href: '/profile' },
  { name: 'sign-out', href: '/api/auth/logout' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function Layout({
  children,
  title = 'slotit.io - book your slots',
  company,
}: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user } = useUser();
  const router = useRouter();
  const { t } = useTranslation('common');

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <div>
        <ToastContainer />
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <Link href="/" legacyBehavior>
                    <a className="flex items-center flex-shrink-0">
                      {company?.logoUrl ? (
                        <Image
                          src={company?.logoUrl}
                          width={60}
                          height={60}
                          alt={company?.logoUrl}
                          className="rounded-full"
                        />
                      ) : (
                        <ClockIcon
                          className="h-12 w-12 text-indigo-600"
                          aria-hidden="true"
                        />
                      )}
                      <span className="ml-2 text-gray-600 hidden lg:flex">
                        {company?.name || process.env.NEXT_PUBLIC_COMPANY_ALIAS}
                      </span>
                    </a>
                  </Link>
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <Link href={item.href} legacyBehavior key={item.name}>
                        <a
                          key={item.name}
                          className={classNames(
                            router.pathname === item.href
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              router.pathname === item.href
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {t(`common:${item.name}`)}
                        </a>
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
          </Dialog>
        </Transition.Root>

        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 relative z-20">
          <div className="flex flex-col flex-grow border-r border-gray-200 bg-white overflow-y-auto">
            <div className="h-20 flex items-center">
              <Link href="/" legacyBehavior>
                <a className="flex items-center flex-shrink-0 px-4">
                  {company?.logoUrl ? (
                    <Image
                      src={company?.logoUrl}
                      width={60}
                      height={60}
                      alt={company?.logoUrl}
                      className="rounded-full"
                    />
                  ) : (
                    <ClockIcon
                      className="h-12 w-12 text-indigo-600"
                      aria-hidden="true"
                    />
                  )}
                  <span className="ml-2 font-medium text-gray-900 hidden lg:flex">
                    {company?.name || process.env.NEXT_PUBLIC_COMPANY_ALIAS}
                  </span>
                </a>
              </Link>
            </div>
            <div className="mt-5 flex-grow flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {navigation.map((item) => (
                  <Link href={item.href} key={item.name} legacyBehavior>
                    <a
                      className={classNames(
                        router.pathname === item.href
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          router.pathname === item.href
                            ? 'text-gray-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {t(`common:${item.name}`)}
                    </a>
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-20 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex"></div>
              <div className="ml-4 items-center flex md:ml-6">
                {user?.name ? (
                  <>
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="whitespace-nowrap px-2 hidden sm:block font-medium text-gray-500 hover:text-gray-900">
                            {user.name}
                          </span>
                          <Image
                            className="inline-block h-9 w-9 rounded-full"
                            src={user.picture}
                            alt={user.name}
                            width="32"
                            height="32"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link href={item.href} legacyBehavior>
                                  <a
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {t(`common:${item.name}`)}
                                  </a>
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </>
                ) : (
                  <a
                    href={`/api/auth/login`}
                    type="button"
                    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span>Login / Sign up</span>
                  </a>
                )}
              </div>
            </div>
          </div>

          <main className="flex-1">
            <div className="py-6">
              <div className="px-4 sm:px-6 md:px-8 relative">
                <div>{children}</div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
