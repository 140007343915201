import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { dateWithTz, GREEK_CITIES } from '../helpers';
import Spinner from '../public/images/spinner.svg';

export function UserDetailsForm({ onSubmit, userEnhanced, loading }) {
  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: userEnhanced,
  });

  useEffect(() => {
    reset({
      ...userEnhanced,
      dateOfBirth: userEnhanced?.dateOfBirth
        ? format(dateWithTz(userEnhanced?.dateOfBirth), 'Y-MM-dd')
        : undefined,
    });
  }, [reset, userEnhanced]);

  const GREEK_STATES = useMemo(
    () =>
      GREEK_CITIES.filter(
        (
          (s) => (o) =>
            ((k) => !s.has(k) && s.add(k))(
              ['admin_name'].map((k) => o[k]).join()
            )
        )(new Set())
      ),
    []
  );

  return (
    <form
      className="space-y-8 divide-y divide-gray-200"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="firstname"
                className="block text-sm font-medium text-gray-700"
              >
                First name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  autoComplete="firstname"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('firstname')}
                  placeholder="John"
                  required
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-gray-700"
              >
                Last name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  autoComplete="lastname"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('lastname')}
                  placeholder="Doe"
                  required
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="dateOfBirth"
                className="block text-sm font-medium text-gray-700"
              >
                Date of birth
              </label>
              <div className="mt-1">
                <input
                  id="dateOfBirth"
                  name="dateOfBirth"
                  type="date"
                  autoComplete="dateOfBirth"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('dateOfBirth')}
                  required
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="gender"
                className="block text-sm font-medium text-gray-700"
              >
                Gender
              </label>
              <div className="mt-1">
                <select
                  id="gender"
                  name="gender"
                  autoComplete="gender"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('gender')}
                >
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  <option value="Empty">Prefer not to say</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm bg-gray-100 border-gray-300 rounded-md"
                  disabled
                  {...register('email')}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone number
              </label>
              <div className="mt-1">
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  autoComplete="phoneNumber"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('phoneNumber')}
                  placeholder="6942288999"
                  required
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="location.country"
                className="block text-sm font-medium text-gray-700"
              >
                Country
              </label>
              <div className="mt-1">
                <select
                  name="location.country"
                  id="location.country"
                  autoComplete="country"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('location.country')}
                  required
                >
                  <option value="GR">Greece</option>
                  <option value="USA">United States</option>
                  <option value="CA">Canada</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="location.city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name="location.city"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Select
                        options={GREEK_CITIES}
                        getOptionValue={(option) => `${(option as any).city}`}
                        getOptionLabel={(option) => option.city}
                        value={GREEK_CITIES.find((c) => c.city === value)}
                        onChange={(val) => onChange(val.city)}
                      />
                    </>
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="location.state"
                className="block text-sm font-medium text-gray-700"
              >
                State / Province
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name="location.state"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="location.state"
                      options={GREEK_STATES}
                      getOptionValue={(option) =>
                        `${(option as any).admin_name}`
                      }
                      getOptionLabel={(option) => option.admin_name}
                      value={GREEK_CITIES.find((c) => c.admin_name === value)}
                      onChange={(val) => onChange(val.admin_name)}
                    />
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="location.address"
                className="block text-sm font-medium text-gray-700"
              >
                Street address
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="location.address"
                  id="location.address"
                  autoComplete="location.address"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('location.address')}
                  placeholder={'Akadimias 24'}
                  required
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="location.postalCode"
                className="block text-sm font-medium text-gray-700"
              >
                ZIP / Postal code
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="location.postalCode"
                  id="location.postalCode"
                  autoComplete="location.postalCode"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('location.postalCode')}
                  placeholder={'41221'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Emergency Contact
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Please share the info below of a person we could reach out in an
              emergency case.
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="emergencyContact.name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="emergencyContact.name"
                  id="emergencyContact.name"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('emergencyContact.name')}
                  placeholder={'Maria Pappa'}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="emergencyContact.phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="emergencyContact.phoneNumber"
                  id="emergencyContact.phoneNumber"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('emergencyContact.phoneNumber')}
                  placeholder={'6999998890'}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="emergencyContact.relationship"
                className="block text-sm font-medium text-gray-700"
              >
                Relationship
              </label>
              <div className="mt-1">
                <select
                  name="emergencyContact.relationship"
                  id="emergencyContact.relationship"
                  autoComplete="emergencyContact.relationship"
                  className="placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('emergencyContact.relationship')}
                >
                  <option value="Family">Family</option>
                  <option value="Friend">Friend</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          {loading ? (
            <button
              disabled
              className="inline-flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <Spinner className="animate-spin h-4 w-4 mr-2" />
              <span className="opacity-50 inline-block">Save</span>
            </button>
          ) : (
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
